import { FirestoreDB } from "./include/creds";
import {
  DocPath,
  DataProblem,
  GetDocResult,
  RemoveDocResult,
  //isUrlValid,
  //OurLogger,
} from "./include/misc";

//const logger: OurLogger = OurLogger.getInstance();

import { collection, doc, deleteDoc, setDoc, getDoc } from "firebase/firestore";

export type fs36PaymentLinks = {
  payment_link_uid?: string | null;
  payment_link_url?: string | null;
  payment_link_description?: string;
  amount: number;
  adjusted_amount?: number;
  order_number: string;
  payment_status?: string;
  last_status_check_time?: Date,
  payment_processor_id?: number;
  terms_type_number: number;
  payment_paid_time?: Date;
  creation_date?: Date;
  pickup_order?: boolean;
  recent_transaction_details?: recentTransactionDetails;
  shipping_address_info?: shippingAddressInfo;
  transaction_id?: string;
  refunded_amount?: number;
  refund_amount?: number;
};

export type recentTransactionDetails = {
  last4_digits: string;
  cardholder_name: string;
  card_type: string;
  avs_status_code: string;
  avs_status_description: string;
  cvv_status_code: string;
  cvv_status_description: string;
}

export type shippingAddressInfo = {
  address_line_1: string;
  address_line_2: string;
  city: string;
  state: string;
  postal_code: string;
}

export function getDataProblems_fs36PaymentLinks(
  data: fs36PaymentLinks
): DataProblem[] {
  const dataproblems: DataProblem[] = [];
  return dataproblems;
}

export function isValidData(data: fs36PaymentLinks): boolean {
  // if (!isUrlValid(data.payment_link_url)) {
  //   logger.error("Data validation error");
  //   return false;
  // }

  return true;
}

export async function getDocFrom_fs36PaymentLinks(
  fs1_id: string,
  fs2_id: string,
  fs4_id: string,
  fs6_id: string,
  fs36_id: string
): Promise<GetDocResult> {
  const result: GetDocResult = {
    debug_data: [],
    return_msg: "fs36PaymentLinks:getDocFrom_fs36PaymentLinks",
    success: true,
    doc_data: {},
  };

  if (!fs1_id || !fs2_id || !fs4_id || !fs6_id || !fs36_id) {
    result.success = false;
    result.return_msg += "Invalid function arguments.";
    return await Promise.resolve(result);
  }

  const doc_ref = doc(
      FirestoreDB,
      "fs1RecyclerBusiness",
      fs1_id,
      "fs2RecyclerLocation",
      fs2_id,
      "fs4ConsumerContacts",
      fs4_id,
      "fs6ConsumerConversations",
      fs6_id,
      "fs36PaymentLinks",
      fs36_id
  );

  const doc_data = (await getDoc(doc_ref)).data();
  if (doc_data === undefined) {
    result.success = false;
    result.return_msg +=
      "Could not find a document with the passed-in path arguments.";
    return await Promise.resolve(result);
  }

  result.doc_data = doc_data;
  return await Promise.resolve(result);
}

export async function addDocIn_fs36PaymentLinks(
  fs1_id: string,
  fs2_id: string,
  fs4_id: string,
  fs6_id: string,
  data: fs36PaymentLinks
): Promise<DocPath> {
  const result: DocPath = {
    id: "",
    path: "",
  };
  if (!isValidData(data)) {
    return await Promise.resolve(result);
  }

  const doc_ref = doc(
    collection(
      FirestoreDB,
      "fs1RecyclerBusiness",
      fs1_id,
      "fs2RecyclerLocation",
      fs2_id,
      "fs4ConsumerContacts",
      fs4_id,
      "fs6ConsumerConversations",
      fs6_id,
      "fs36PaymentLinks"
    )
  );
  data.payment_link_url = null;
  data.payment_link_uid = doc_ref.id;
  await setDoc(doc_ref, data);

  result.id = doc_ref.id;
  result.path = doc_ref.path;
  return await Promise.resolve(result);
}

export async function setDocIn_fs36PaymentLinks(
  fs1_id: string,
  fs2_id: string,
  fs4_id: string,
  fs6_id: string,
  fs36_id: string,
  data: fs36PaymentLinks
): Promise<DocPath> {
  const result: DocPath = {
    id: "",
    path: "",
  };
  if (!isValidData(data)) {
    return await Promise.resolve(result);
  }

  const doc_ref = doc(
      FirestoreDB,
      "fs1RecyclerBusiness",
      fs1_id,
      "fs2RecyclerLocation",
      fs2_id,
      "fs4ConsumerContacts",
      fs4_id,
      "fs6ConsumerConversations",
      fs6_id,
      "fs36PaymentLinks",
      fs36_id
  );

  await setDoc(doc_ref, data);

  result.id = doc_ref.id;
  result.path = doc_ref.path;
  return await Promise.resolve(result);
}

export async function removeDocFrom_fs36PaymentLinks(
  fs1_id: string,
  fs2_id: string,
  fs4_id: string,
  fs6_id: string,
  fs36_id: string
): Promise<RemoveDocResult> {
  const result: RemoveDocResult = {
    debug_data: [],
    return_msg: "fs36PaymentLinks:removeDocFrom_fs36PaymentLinks ",
    success: true,
  };

  if (!fs1_id || !fs2_id || !fs4_id || !fs6_id || !fs36_id) {
    result.success = false;
    result.return_msg += "Invalid function arguments.";
    return await Promise.resolve(result);
  }

  const doc_ref = doc(
    collection(
      FirestoreDB,
      "fs1RecyclerBusiness",
      fs1_id,
      "fs2RecyclerLocation",
      fs2_id,
      "fs4ConsumerContacts",
      fs4_id,
      "fs6ConsumerConversations",
      fs6_id,
      "fs36PaymentLinks"
    ),
    fs36_id
  );

  await deleteDoc(doc_ref);
  return await Promise.resolve(result);
}
