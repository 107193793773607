import { Unsubscribe } from "@firebase/util";
import {
  collection,
  doc,
  onSnapshot,
  getDoc,
  query,
  where,
  Firestore,
  getDocs,
  orderBy,
  collectionGroup,
  //Timestamp,
} from "firebase/firestore";

import Vue, { ref } from "vue";
import { Ref } from "vue";
import { fs1RecyclerBusiness } from "./firestore_schemas/fs1RecyclerBusiness";
import { fs2RecyclerLocation } from "./firestore_schemas/fs2RecyclerLocation";
import { fs3Employees } from "./firestore_schemas/fs3Employees";
import { fs4ConsumerContacts } from "./firestore_schemas/fs4ConsumerContacts";
import { fs6ConsumerConversations } from "./firestore_schemas/fs6ConsumerConversations";
import { fs10MessagesWithConsumer } from "./firestore_schemas/fs10MessagesWithConsumer";
import { fs13EmployeeFeedbackMessages } from "./firestore_schemas/fs13EmployeeFeedbackMessages";
import { fs14PaymentMethod } from "./firestore_schemas/fs14PaymentMethod";
import { fs19BrowserCacheConsumerContactSearch } from "./firestore_schemas/fs19BrowserCacheConsumerContactSearch";
import { fs23BrowserCacheConsumerContactsSearchUpdates } from "./firestore_schemas/fs23BrowserCacheConsumerContactsSearchUpdates";
import { fs27ConsumerConversationQueue } from "./firestore_schemas/fs27ConsumerConversationQueue";
import { fs29FirebaseAccounts } from "./firestore_schemas/fs29FirebaseAccounts";
import { fs30EmployeeAccountRecyclerJoins } from "./firestore_schemas/fs30EmployeeAccountRecyclerJoins";
import { fs34RecyclerStaffOnlyMessages} from "./firestore_schemas/fs34RecyclerStaffOnlyMessages"
import { fs35ConversationVehicle } from "./firestore_schemas/fs35ConversationVehicle";
import { fs36PaymentLinks} from "./firestore_schemas/fs36PaymentLinks";
import { fs37ConsumerBusinesses } from "./firestore_schemas/fs37ConsumerBusinesses";
import { fs38VehicleTypes } from "./firestore_schemas/fs38VehicleTypes";
import { fs73EmployeeConversationInviteFolderEntries } from "./firestore_schemas/fs73EmployeeConversationInviteFolderEntries";
import { fs39ConsumerBusinessesCategories } from "./firestore_schemas/fs39ConsumerBusinessesCategories";
import { fs43RecentQueueEntries } from "./firestore_schemas/fs43RecentQueueEntries";
import { fs45RecentRemovedQueueEntries } from "./firestore_schemas/fs45RecentRemovedQueueEntries";
import { fs46EmployeeActiveConsumerConversationJoins } from "./firestore_schemas/fs46EmployeeActiveConsumerConversationJoins";
import { fs50CacheUpdatedRecords } from "./firestore_schemas/fs50CacheUpdatedRecords";
import { fs5RecyclerLocationInvites } from "./firestore_schemas/fs5RecyclerLocationInvites"
import { fs76RecyclerLocationInvitesSubcollection } from "./firestore_schemas/fs76RecyclerLocationInvitesSubcollection"
import { fs20BrowserCacheConversationSearch } from "./firestore_schemas/fs20BrowserCacheConversationSearch";
import { fs21BrowserCacheConversationSearchUpdates } from "./firestore_schemas/fs21BrowserCacheConversationSearchUpdates";
import { fs85RecyclerLocationLogos } from "./firestore_schemas/fs85RecyclerLocationLogos";
import { fs83PaymentApiKeys } from "./firestore_schemas/fs83PaymentApiKeys";
import { fs103ConversationStatuses } from "./firestore_schemas/fs103ConversationStatuses";
import { fs105LocationCandidates } from "./firestore_schemas/fs105LocationCandidates";
import { fs107StripeIdentityVerificationDetails } from "./firestore_schemas/fs107StripeIdentityVerificationDetails";
import { fs112ConsumerConversationNotifications } from "./firestore_schemas/fs112ConsumerConversationNotifications";
import { fs125ReadNotifications } from "./firestore_schemas/fs125ReadNotifications";
import { fs130RecyclerTermsAndConditions } from "./firestore_schemas/fs130RecyclerTermsAndConditions";
import { fs133ImportedContactsHistory } from "./firestore_schemas/fs133ImportedContactsHistory";
import { fs138AVSverificationPlans } from "./firestore_schemas/fs138AVSverificationPlans";
import { fs139RecyclerConversationStarters } from "./firestore_schemas/fs139RecyclerConversationStarters";

type Result = {
  debug_data: Array<object>;
  return_msg: string;
  success: boolean;
  //used by listenerExistsCheck
  exists?: boolean;
  //used by findConsumerByPhoneNumber
  consumer_uid?: string;
};

export class bi2DataInteractions {
  IV_is_instance_ready = false;



//TODO SBD all these change value flags vue.js components can put watchers on
IV_consumer_business_data_last_changed: Ref<any>;
IV_consumer_contacts_data_last_changed: Ref<any>;

  //TODO SBD
  IV_schema_ids: any;

  //TODO SBD and add typescript structure types
  IV_consumer_phone_lookup: Ref<{
    [first_number: string]: {
      [second_number: string]: {
        [third_number: string]: {
          [fourth_number: string]: {   
            [fifth_number: string]: {
              [sixth_number: string]: {
                [seventh_number: string]: {
                  [eighth_number: string]: {
                  [full_phone_number: string] : string;  
                  };
                };        
              };               
            };       
          };

        };
      };
    };
  }>;

  IV_fs23_last_update_applied_time_by_location: {
    [recycler_location_uid: string]: Date | undefined;
  };
  
  IV_fs21_last_update_applied_time_by_location: {
    [recycler_location_uid: string]: Date | undefined;
  };
  
  // source: fs2, fs3
  IV_logged_in_user_employee_data_per_location: Ref<{
    // from: fs2
    [recycler_location_uid: string]: {
      // from: fs3
      first_name: string;
      last_name: string;
      email: string;
      phone_number: string;
    };
  }>;
  
  // source: fs14
  IV_payment_method: Ref<{
    [billing_method_uid: string]: {
      customer_id: string;
      creation_day: Date;
      card_type: string;
      last_4_digits: string;
      payment_method_status: 1|2|3;
    }
  }>

  // source: fs29
  IV_loggedin_user_business_ids: Ref<Array<string>>;
  IV_loggedin_user_business_ids_fetched: boolean;

  // source: fs30
  IV_employee_account_recycler_joins: Ref<{
    [employee_uid: string]: {
      employee_uid: string;
      recycler_location_uid: string;
      business_uid: string;
    };
  }>;

  // source: fs1
  IV_businesses: Ref<{
    [business_uid: string]: {
      name: string;
      phone_number?: string;
      owner_email?: string;
      is_archived?: boolean;
      business_type?: number;
    };
  }>;

  // source: fs1, fs2
  IV_recyclers: Ref<{
    // from: fs2
    [recycler_location_uid: string]: {
      // from: fs1
      recycler_business_uid: string;
      recycler_business_name: string;
      // from: fs2
      recycler_location_name: string;
      phone_number: string;
      email: string;
      city: string;
      state: string;
      postal_code: number;
      address_line_1: string;
      address_line_2?: string;
      text_number: string;
      text_number_sid: string;
      website: string;
      google_maps_url: string;
      facebook_url: string;
      payment_api_keys_lookup_uid: string;
      review_provider_account_uid: string;
      assigned_payment_method: string;
      sms_only_messaging: boolean;
      connect_account_id: string;
      conversation_starters_enabled: boolean;
      timezone: string;
      days_open: string[];
      business_hours: string[];
      autosend_review_link_url: boolean;
      after_hours_message: string;
    };
  }>;

  // source: fs138
  IV_avs_verification_plans: Ref<{
    [payment_processor_id: string]: {
      avs_verification_type: number;
    }
  }>;

  IV_stripe_identity_verification: Ref<{
    [recycler_location_uid: string]: {
      stripe_identity_verification_enabled: boolean;
      minimum_transaction_amount: number;
    }
  }>;

  // TODO - Document in SBD
  IV_recyclers_logo_urls: {
    [recycler_location_uid: string]: boolean;
  };

  IV_recyclers_payment_api_keys: Ref<{
    [fs83_doc_uid: string]: any;
  }>;

  IV_recyclers_location_candidates: Ref<{
    [recycler_location_uid: string]: any;
  }>

  // TODO - Document in SBD
  IV_business_recycler_joins: Ref<{
    [business_uid: string]: {
      recycler_location_uid: string;
    }
  }>;

  // TODO - Document in SBD
  IV_conversations_unread_messages_count: Ref<{
    [consumer_and_conversation_uuid: string]: number;
  }>;

  // TODO - Document in SBD
  // source: fs37
  IV_consumer_businesses: Ref<{
    [consumer_business_uid: string]: {
      consumer_business_uid: string;
      consumer_business_type_uids_list: Array<string>;
      primary_consumer_contact_uid: string;
      name: string;
      city: string;
      state: string;
      postal_code: number;
      address_line_1: string;
      address_line_2: string;
      website: string;
      facebook_url: string;
      consumer_contacts_uids_list: Array<string>;
    };
  }>;

  // TODO - Document in SBD
  // source: fs112
  IV_user_notifications: Ref<{
    [uid: string]: {
      type: number;
      timestamp?: Date;
      summary?: string;
      description?: string;
      json_data?: string;
      consumer_conversation_uid: string;
      consumer_uid: string;
      conversation_uid: string;
      payment_link_uid?: string;
    }
  }>;

  // TODO - Document in SBD
  // source: fs125
  IV_user_notifications_read_time: Ref<{
    [uid: string]: {
      consumer_contact_uid: string;
      conversation_uid: string;
      read_message_time: Date;
      read_notification_time: Date;
    }
  }>;

  // source: fs39
  IV_consumer_business_types: Ref<{
    // from: fs2
    [recycler_location_uid: string]: {
      // from: fs37
      [consumer_business_type_uids_list: string]: {
        // from: fs39
        uid: string;
        name: string;
        description: string;
      };
    };
  }>;

  //TODO SBD - this is used to help components match the last phone number a user created a conversation with, so we can automatically open it when it loads data
  IV_last_new_conversation_phone_number: string;
  
  // sources: fs25, fs3
  IV_recycler_employees: Ref<{
    //* FROM: fs3 *//
    [employee_uid: string]: {
      //* FROM: fs3 *//
      employee_uid: string;
      first_name: string;
      last_name: string;
      email_address: string;
      phone_number: string;
      average_employee_rating?: number;
      permissions: {
        admin?: boolean;
        sales_person?: boolean;
        manager?: boolean;
        report_viewer?: boolean;
      };
    };
  }>;

  // source: fs4
  IV_consumer_contacts_pending: {
    [consumer_uid: string]: boolean;
  };

  IV_al_consumer_contacts: {
    consumer_uid: 0;
    first_name: 1;
    last_name: 2;
    email: 3;
    firestore_uid: 4;
    landline_numbers: 5;
    average_consumer_rating: 6;
    average_employee_rating: 7;
    address_info_city_2d: [8, 0];
    address_info_state_2d: [8, 1];
    address_info_postal_code_2d: [8, 2];
    address_info_address_line_1_2d: [8, 3];
    address_info_address_line_2_2d: [8, 4];
    consumer_businesses_uids: 9 ;
    assigned_employees: 10,
    facebook_profile_url: 11;
    primary_phone_number: 12;
    customer_types: 13;
    is_deactivated: 14;
    verified_outputs: 15;
    shipping_address_info_city_2d: [16, 0];
    shipping_address_info_state_2d: [16, 1];
    shipping_address_info_postal_code_2d: [16, 2];
    shipping_address_info_address_line_1_2d: [16, 3];
    shipping_address_info_address_line_2_2d: [16, 4];
  };

  IV_consumer_conversation_payment_links: Ref <{
    [conversation_uuid: string]: {
      [payment_link_uid: string]: {
        consumer_uid: string;
        conversation_uid: string;
        order_number?: string;
        url?: string;
        description: string;
        amount: number;
        adjusted_amount: number;
        date_paid?: Date;
        payment_link_uid: string;
        terms_type_number: number;
        creation_date?: Date;
        pickup_order?: string;
        recent_avs_status_code?: string;
        recent_transaction_details: Ref <{
          last4_digits: string;
          cardholder_name: string;
          card_type: string;
        }>
        transaction_id: string;
        refunded_amount: number;
        refund_amount: number;
      };
    };
  }>;

  IV_transaction_terms_and_conditions: Ref <{
    [business_and_recycler_location_and_type_number_uuid: string]: {
      type_number: number;
      type_description: string;
      transaction_terms_and_conditions: string;
    }
  }>

  IV_recycler_conversation_starters: Ref <{
    [business_and_recycler_location_uuid: string]: {
      subject: string;
      body: string;
    }
  }>

  IV_imported_contacts_history_last_changed: Ref<any>;;

  IV_imported_contacts_history: Ref <{
    [imported_contacts_history_uuid: string]: [
      imported_contacts_uid: string,
      consumer_phone_numbers: Array<string>,
      remote_blob_path: string,
      date_uploaded: Date,
      file_name: string
    ];
  }>

  IV_consumer_identity_verification_links: Ref <{
    [consumer_and_conversation_uuid: string]: {
      verification_session_id: string;
      verification_link: string;
      redirect_link: string;
      status: string;
      time_verified: Date;
      last_time_event_received: Date;
    };
  }>;

  IV_loggedin_user_active_invites: Ref<{
    [recycler_business_location_uid: string]: Array<{
      permissions: string;
      invite_time: Date;
      recycler_business_uid: string;
      recycler_location_uid: string;
      recycler_business_name: string;
      recycler_location_name: string;
      subcollection_doc_id: string
    }>
  }>;

  // sources: fs2, fs4, fs37
  IV_consumer_contacts: Ref<{
    //* FROM: fs4 *//
    [consumer_uid: string]: [
      //* FROM: fs4 *//
      consumer_uid: string,
      first_name: string,
      last_name: string,
      email: string | undefined,
      firestore_uid: string | undefined,
      phone_number: Array<{
        type: 1 | 2,
        phone_number: string,
        extension: string | undefined,
        description: string | undefined
      }>,
      average_consumer_rating: number | undefined,
      average_employee_rating: number | undefined,
      address_info: [
        city: string,
        state: string,
        // NOTE: Is string in recyclers sbd but number in GCP blueprint
        postal_code: number,
        address_line_1: string,
        address_line_2: string | undefined
      ],

      //* FROM: fs37 *//
      consumer_businesses_uids: Array<string>,
      assigned_employees: Array<string>,
      facebook_profile_url: string,
      primary_phone_number: {
        type: 1 | 2,
        phone_number: string,
        extension: string | undefined,
        description: string | undefined
      },
      customer_types: Array<string>,
      verified_outputs: Array<{
        first_name: string,
        last_name: string,
        city: string,
        country: string,
        line1: string,
        line2: string,
        postal_code: string,
        state: string,
        time_verified: Date,
        matched_profile: number
      }>
    ];
  }>;

  IV_consumer_contacts_last_updated: bigint;

  // source: fs6
  IV_consumer_conversations_pending: {
    [consumer_uid: string]: boolean;
  };

  IV_al_employee_conversations: {
    message_uid: 0;
    message_type: 1;
    employee_uid: 2;
    timestamp: 3;
    message_content: 4;
    file_url: 4;
    file_name: 5;
    file_size: 6;
    file_type: 7;
  };

  IV_al_consumer_conversation_messages: {
    message_sender: 0;
    message_type: 1;
    message_uid: 2;
    employee_uid: 3;
    timestamp: 4;
    message_content: 5;
    file_url: 5;
    file_name: 6;
    file_size: 7;
    file_type: 8;
  };

  // TODO: SBD
  IV_activity_status_inactive_time: number;
  IV_activity_status_offline_time: number;
  
  // TODO: SBD
  // sources: fs101, fs102, fs103
  IV_conversations_activity_statuses: Ref<{
    [consumer_conversation_uid: string]: {
      last_activity_time: Date | string;
      employee_typing_status: string;
      consumer_typing_status: string;
    }
  }>;


  // sources: fs6,fs10
  IV_consumer_conversation_last_consumer_message: Ref<{
    [conversation_uuid: string] :[
      sender: 1 | 2 | 3 | 4 | 5,
      type: 1 | 2 | 3,
      message_uid: string,
      employee_uid: string | undefined,
      timestamp: Date,
      content_or_file_link: string,
      uploaded_file_link?: string,
      file_name?: string,
      file_size?: number,
      file_type?: 1| 2 | 3
    ]
  }>;


  // sources: fs6, fs10
  IV_consumer_conversation_messages: Ref<{
    // FROM: fs6
    [conversation_uuid: string]: Array<
      // FROM: fs10
      [
        sender: 1 | 2 | 3 | 4 | 5,
        type: 1 | 2 | 3,
        message_uid: string,
        employee_uid: string | undefined,
        timestamp: Date,
        content_or_file_link: string,
        uploaded_file_link?: string,
        file_name?: string,
        file_size?: number,
        file_type?: 1| 2 | 3
      ]
    >;
  }>;

  // sources: fs6, fs34
  IV_employee_conversations: Ref<{
    [conversation_uuid: string]: Array<
      [
        message_uid: string | undefined,
        type: 1 | 2 | 3,
        employee_uid: string | undefined,
        timestamp: Date,
        message_content: string | undefined,
        file_name?: string | undefined,
        file_size?: number | undefined,
        file_type?: number | undefined

      ]
    >;
  }>;


  //TODO SBD
  IV_consumer_conversation_counts: Ref<{
    [consumer_uid: string] : number;
  }>;

  IV_conversation_queue_entries: Ref<{
    [consumer_and_conversation_uuid: string]: {
      consumer_uid: string,
      conversation_uid: string
    }
  }>;

  IV_al_consumer_conversation_details: {
    consumer_uid: 0;
    conversation_uid: 1;
    consumer_and_conversation_uuid: 2;
    entered_queue_timestamp: 3;
    consumer_name: 4;    
    message_uid: 5;
    is_active_queue_entry: 6;
    consumer_business_name: 7;
    phone_number: 8;
    ma1_vehicles_and_parts_array: 9;
    ma1i_type: 0;
    ma1i_interchange_part_number: 1;
    ma1i_vehicle_make_name: 2;
    ma1i_vehicle_model_name: 3;
    ma1i_vehicle_year: 4;
    mali_parts_description: 5;
    ma2_vehicle_part_summary_data: 10;
    ma2i_total_count: 0;
    ma2i_vehicle_count: 1;
    ma2i_interchange_part_number_count: 2;
    ma2i_last_entry_added_summary: 3;
  };

  // sources: fs4, fs6, fs10, fs27
  IV_consumer_conversation_details: Ref<{
    // FROM: fs6
    [consumer_and_conversation_uuid: string]: [
      // FROM: fs4
      consumer_uid: string,
      // FROM: fs27
      entered_queue_timestamp: Date,
      // FROM: fs4
      consumer_name: string,
      // FROM: fs27
      conversation_uid: string,
      // FROM: fs10
      message_uid: string,
      // FROM: fs6
      is_active_queue_entry: boolean,
      conversation_type: 1 | 2 | 3 | 4,
      // FROM: fs37
      business_name: string,
      // FROM fs4
      phone_number: string,
      // FROM: fs35
      vehicles_and_interchange_parts: {
        [fs35_doc_id: string]: Array<[
          type: 1 | 2,
          interchange_part_number?: string,
          vehicle_manufacturer_name?: string,
          vehicle_model_name?: string,
          vehicle_year?: number,
          vehicle_parts_description?: string,
        ]>
      },
      vehicles_and_interchange_parts_summary: Array<[
        total_count: number,
        vehicle_count: number,
        interchange_part_number_count: number
      ]>
    ];
  }>;

  IV_document_active_listeners: any;
  IV_consumer_conversations_last_updated: bigint;

  // sources: fs6, fs4, fs37, fs2
  IV_consumer_conversations_ref: {
    // from: fs6
    [conversation_uid: string]: {
      consumer_uid: string;
      // from: fs4
      consumer_name: string;
      // from: fs6
      type: 1 | 2 | 3 | 4;
      // from: fs37
      business_name: string;
      // from: fs2
      recycler_name: string;
      // from: fs6
      consumer_rating: number;
      // from: fs4
      phone_number: string;
    };
  };

  IV_consumer_conversations: Ref<{
    // from: fs6
    [consumer_conversation_uid: string]: {
      uuid: string;
       // from: fs4
      consumer_uid: string;           
      consumer_name: string;
      // from: fs6
      conversation_uid: string;
      type: 1 | 2 | 3 | 4;
      assigned_employee: string;
      // from: fs37
      business_name: string;
      // from: fs2
      recycler_name: string;
      // from: fs6
      consumer_rating: number;
      // from: fs4
      phone_number: string;
      // from: fs6
      conversation_invite_url: string;

      // from: fs6
      last_message_timestamp: Date;

      // from: fs103
      last_activity_time: Date;
      activity_status: string;
      consumer_typing_status: string;
      entered_queue_timestamp: Date | undefined;
    };
  }>;
  // sources: fs4
  IV_consumer_contact_conversation_history_index_pending: {
    [consumer_uid: string]: boolean;
  };

  IV_al_consumer_contact_conversation_history_index: {
    consumer_conversation_uid: 0;
    consumer_uid: 1;
    chat_start_timestamp: 2;
    chat_finished_timestamp: 3;
    conversation_consumer_rating: 4;
  };

  // sources: fs4, fs6
  IV_consumer_contact_conversation_history_index: Ref<{
    // from: fs4
    [consumer_uid: string]: {
      // from: fs6
      [conversation_uid: string]: [
        consumer_conversation_uid: string,
        // from: fs4
        consumer_uid: string,
        // from: fs6
        chat_start_timestamp: Date | undefined,
        chat_finished_timestamp: Date | undefined,
        conversation_consumer_rating: number | undefined
      ];
    };
  }>;

  IV_consumer_business_joins: Ref<{
    [consumer_uid: string]: any;
  }>;


  // sources: fs4
  IV_consumer_feedback_logs_pending: {
    [consumer_uid: string]: boolean;
  };

  IV_al_consumer_feedback_logs: {
    consumer_conversation_uid: 0;
    consumer_uid: 1;
    conversation_consumer_rating: 2;
    ma1_employee_feedback_messages_array: 3;
    ma1i_uid: 0;
    ma1i_type: 1;
    ma1i_content: 2;
    ma1i_timestamp: 3;
    ma1i_employee_uid: 4;
    ma1i_rating: 5;
  };

  // sources: fs43
  IV_recent_queue_entries: {
    [conversation_uid: string]: boolean;
  };

  // sources: fs43
  IV_recent_removed_queued_entries: {
    [conversation_uid: string]: boolean;
  };

  //TODO add to SBD
  IV_all_active_conversations: Ref<{
    // from fs46
    //this is by consumer_uid since a consumer can only have 1 active conversation at a time
    [consumer_uid: string]: {
      [consumer_conversation_uid: string]: {
        conversation_uid: string;
        consumer_uid: string;
      };
    };
  }>;

  IV_employees_active_conversations: Ref<{
    [consumer_conversation_uid: string]: {
      conversation_uid: string;
      consumer_uid: string;
    };
  }>;

  // sources: fs4, fs6, fs13
  IV_consumer_feedback_logs: Ref<{
    [consumer_uid: string]: {
          [message_uid: string]: [
            consumer_conversation_uid: string,
            type: 1 | 2,
            text_or_url: string,
            timestamp: Date,
            employee_uid: string
          ];
        }
  }>;

  IV_collection_active_listeners: {
    [collection_name: string]: any;
  };

  IV_firebase_app: Firestore;

  IV_vue_app: Vue | undefined;

  IV_loggedin_user_info: {
    email: string;
    firebase_uid: string;
    first_name: string;
    last_name: string;
    display_name: string;
  };

  IV_active_employee_info: {
    business_uid: string;
    business_name: string;
    recycler_location_uid: string;
    recycler_location_name: string;
    employee_uid: string;
    is_business_owner: boolean;
    permissions: {
      admin: boolean;
      sales_person: boolean;
      manager: boolean;
      report_viewer: boolean;
    }
  };

  IV_yard_side_chat_invites: Ref<{
    [conversation_uuid: string]: {
      consumer_uid: string;
      conversation_uid: string;
      invited_timestamp: Date;
      inviting_employee_uid: string;
      last_response?: 1 | 2;
      response_timestamp: Date;
    }
  }>;



  /*
  IV_consumer_contact_search_data: Ref<{
    consumer_uids: Array<string>;
    business_uids: Array<string>
    business_consumer_joins:  {
    [consumer_business_uid: string] : Array<string>;
    };
    consumer_business_joins: {
      [consumer_uid: string] : Array<string>;
    };
    first_name_lookup: {
      [first_letter: string]: 
        {
          [second_letter: string]: 
          {
            [third_letter: string]: 
              Array<number>; // Contains the array indexes of consumer from IV_consumer_contact_search_data > consumer_uids whose first name match with first 3 letters 
          }
        }
    };
    last_name_lookup: {
      [first_letter: string]: 
        {
          [second_letter: string]: 
          {
            [third_letter: string]: 
              Array<number>; // Contains the array indexes of consumer from IV_consumer_contact_search_data > consumer_uids whose last name match with first 3 letters 
          }
        }
    };
    business_name_lookup: {
      [first_letter: string]: 
        {
          [second_letter: string]: 
          {
            [third_letter: string]: 
              Array<number>; // Contains the array indexes of consumer from IV_consumer_contact_search_data > business_uids whose last name match with first 3 letters 
          }
        }
    };
    postal_code_lookup: {
      [first_number: number]: 
        {
          [second_number: number]: 
          {
            [third_number: number]: 
              Array<number>; // Contains the array indexes of consumer from IV_consumer_contact_search_data > consumer_uids  of the consumer UID whos fs4 record indicates their address is in this postal code
          }
        }
    };
    state_lookup:  {
      [state_name: string]: 
        Array<number>; // Here it contains the state as an index that helps to find the consumer(s) uids index reference based on that state
    };
    average_employee_rating_lookup:{
     [rating_value: number]:
        Array<number>; // This structure provides the ability to get the employee(s) based on the average rating or average rating range
    };
    average_consumer_rating_lookup:{
      [rating_value: number]:
          Array<number>; // the array index in json_cache_data > "consumer_uids" of the consumer UID whose fs4ConsumerContacts record
    };
    phone_number_lookup:{
      [phone_number: string]:
          Array<number>; // the array index in json_cache_data > "consumer_uids" of the consumer UID whos fs4 record shows they are having this phone number
    };
  }>;
  */
  /*
  type_IV_consumer_contact_search_data: {
    consumer_uids: Array<string>;
    business_uids: Array<string>
    business_consumer_joins:  {
    [consumer_business_uid: string] : Array<string>;
    };
    consumer_business_joins: {
      [consumer_uid: string] : Array<string>;
    };
    first_name_lookup: {
      [first_letter: string]: 
        {
          [second_letter: string]: 
          {
            [third_letter: string]: 
              Array<number>; // Contains the array indexes of consumer from IV_consumer_contact_search_data > consumer_uids whose first name match with first 3 letters 
          }
        }
    };
    last_name_lookup: {
      [first_letter: string]: 
        {
          [second_letter: string]: 
          {
            [third_letter: string]: 
              Array<number>; // Contains the array indexes of consumer from IV_consumer_contact_search_data > consumer_uids whose last name match with first 3 letters 
          }
        }
    };
    business_name_lookup: {
      [first_letter: string]: 
        {
          [second_letter: string]: 
          {
            [third_letter: string]: 
              Array<number>; // Contains the array indexes of consumer from IV_consumer_contact_search_data > business_uids whose last name match with first 3 letters 
          }
        }
    };
    postal_code_lookup: {
      [first_number: number]: 
        {
          [second_number: number]: 
          {
            [third_number: number]: 
              Array<number>; // Contains the array indexes of consumer from IV_consumer_contact_search_data > consumer_uids  of the consumer UID whos fs4 record indicates their address is in this postal code
          }
        }
    };
    state_lookup:  {
      [state_name: string]: 
        Array<number>; // Here it contains the state as an index that helps to find the consumer(s) uids index reference based on that state
    };
    average_employee_rating_lookup:{
     [rating_value: number]:
        Array<number>; // This structure provides the ability to get the employee(s) based on the average rating or average rating range
    };
    average_consumer_rating_lookup:{
      [rating_value: number]:
          Array<number>; // the array index in json_cache_data > "consumer_uids" of the consumer UID whose fs4ConsumerContacts record
    };
    phone_number_lookup:{
      [phone_number: string]:
          Array<number>; // the array index in json_cache_data > "consumer_uids" of the consumer UID whos fs4 record shows they are having this phone number
    };
  };
  

  IV_consumer_contact_search_data: Ref<typeof this.type_IV_consumer_contact_search_data>;
  */
  IV_consumer_contact_search_data: Ref<JSON>;

  IV_conversation_search_data: Ref<JSON>;

  constructor(firestore_app_reference: Firestore) {
    this.IV_is_instance_ready = false;
    this.IV_consumer_business_data_last_changed = ref(false);
    this.IV_consumer_contacts_data_last_changed = ref(false);
    this.IV_imported_contacts_history_last_changed = ref(false);
    this.IV_schema_ids = {}
    this.IV_fs23_last_update_applied_time_by_location = {};
    this.IV_fs21_last_update_applied_time_by_location = {};
    this.IV_loggedin_user_business_ids = ref([]);
    this.IV_loggedin_user_business_ids_fetched = false;
    this.IV_employee_account_recycler_joins = ref({});
    this.IV_consumer_phone_lookup = ref({});
    this.IV_businesses = ref({});
    this.IV_payment_method = ref({});
    this.IV_recyclers = ref({});
    this.IV_avs_verification_plans = ref({});
    this.IV_stripe_identity_verification = ref({});
    this.IV_business_recycler_joins = ref({});
    this.IV_logged_in_user_employee_data_per_location = ref({});
    this.IV_user_notifications = ref({});
    this.IV_user_notifications_read_time = ref({});
    this.IV_consumer_business_types = ref({});
    this.IV_consumer_businesses = ref({});
    this.IV_conversations_unread_messages_count = ref({});
    this.IV_consumer_business_joins = ref({});
    this.IV_recycler_employees = ref({});
    this.IV_consumer_contacts = ref({});
    this.IV_consumer_contacts_last_updated = BigInt(0);
    this.IV_consumer_conversations_last_updated = BigInt(0);
    this.IV_consumer_conversation_messages = ref({});
    this.IV_consumer_conversation_last_consumer_message = ref({});
    this.IV_employee_conversations = ref({});
    this.IV_conversations_activity_statuses = ref({});
    this.IV_activity_status_inactive_time = 15;
    this.IV_activity_status_offline_time = 45;
    this.IV_consumer_conversation_counts = ref({});
    this.IV_document_active_listeners = {
      fs1RecyclerBusiness: {},
      fs29FirebaseAccountsOnly: {},
      fs29FirebaseAccounts: {},
      fs21BrowserCacheConversationSearchUpdates: {},
      fs23BrowserCacheConsumerContactsSearchUpdates: {},
      fs5RecyclerLocationInvites: {}
    };
    this.IV_loggedin_user_active_invites = ref({});
    this.IV_collection_active_listeners = {
      fs1RecyclerBusiness: { doc: {} },
    };

    this.IV_firebase_app = firestore_app_reference;

    this.IV_loggedin_user_info = {
      email: "",
      firebase_uid: "",
      first_name: "",
      last_name: "",
      display_name: ""
    };


    this.IV_conversation_queue_entries = ref({});
    this.IV_yard_side_chat_invites = ref({});
    this.IV_last_new_conversation_phone_number = "";
    this.IV_active_employee_info = {
      business_uid: "",
      business_name: "",
      recycler_location_uid: "",
      recycler_location_name: "",
      employee_uid: "",
      is_business_owner: false,
      permissions: {
        admin: false,
        sales_person: false,
        manager: false,
        report_viewer: false
      },
    };

    this.IV_vue_app = undefined;
    this.IV_consumer_contacts_pending = {};
    this.IV_recyclers_logo_urls = {};
    this.IV_recyclers_payment_api_keys = ref({});
    this.IV_recyclers_location_candidates = ref({});

    this.IV_al_consumer_contacts = {
      consumer_uid: 0,
      first_name: 1,
      last_name: 2,
      email: 3,
      firestore_uid: 4,
      landline_numbers: 5,
      average_consumer_rating: 6,
      average_employee_rating: 7,
      address_info_city_2d: [8, 0],
      address_info_state_2d: [8, 1],
      address_info_postal_code_2d: [8, 2],
      address_info_address_line_1_2d: [8, 3],
      address_info_address_line_2_2d: [8, 4],
      consumer_businesses_uids: 9,
      assigned_employees: 10,
      facebook_profile_url: 11,
      primary_phone_number: 12,
      customer_types: 13,
      is_deactivated: 14,
      verified_outputs: 15,
      shipping_address_info_city_2d: [16, 0],
      shipping_address_info_state_2d: [16, 1],
      shipping_address_info_postal_code_2d: [16, 2],
      shipping_address_info_address_line_1_2d: [16, 3],
      shipping_address_info_address_line_2_2d: [16, 4],
    };
    this.IV_consumer_conversation_payment_links = ref({});
    this.IV_transaction_terms_and_conditions = ref({});
    this.IV_recycler_conversation_starters = ref({});
    this.IV_imported_contacts_history = ref({});
    this.IV_consumer_identity_verification_links = ref({});
    this.IV_consumer_conversations_pending = {};

    this.IV_al_consumer_conversation_messages = {
      message_sender: 0,
      message_type: 1,
      message_uid: 2,
      employee_uid: 3,
      timestamp: 4,
      message_content: 5,
      file_url: 5,
      file_name: 6,
      file_size: 7,
      file_type: 8
    };


    this.IV_al_employee_conversations = {
      message_uid: 0,
      message_type: 1,
      employee_uid: 2,
      timestamp: 3,
      message_content: 4,
      file_url: 4,
      file_name: 5,
      file_size: 6,
      file_type: 7

    };


    this.IV_al_consumer_conversation_details = {
      consumer_uid: 0,
      conversation_uid: 1,
      consumer_and_conversation_uuid: 2,
      entered_queue_timestamp: 3,
      consumer_name: 4,
      message_uid: 5,
      is_active_queue_entry: 6,
      consumer_business_name: 7,
      phone_number: 8,
      ma1_vehicles_and_parts_array: 9,
      ma1i_type: 0,
      ma1i_interchange_part_number: 1,
      ma1i_vehicle_make_name: 2,
      ma1i_vehicle_model_name: 3,
      ma1i_vehicle_year: 4,
      mali_parts_description: 5,
      ma2_vehicle_part_summary_data: 10,
      ma2i_total_count: 0,
      ma2i_vehicle_count: 1,
      ma2i_interchange_part_number_count: 2,
      ma2i_last_entry_added_summary:3
    };

    this.IV_consumer_conversation_details = ref({});

    this.IV_consumer_conversations_ref = {};

    this.IV_consumer_contact_conversation_history_index_pending = {};

    this.IV_al_consumer_contact_conversation_history_index = {
      consumer_conversation_uid: 0,
      consumer_uid: 1,
      chat_start_timestamp: 2,
      chat_finished_timestamp: 3,
      conversation_consumer_rating: 4,
    };

    this.IV_consumer_contact_conversation_history_index = ref({});

    this.IV_consumer_feedback_logs_pending = {};
    this.IV_recent_queue_entries = {};
    this.IV_recent_removed_queued_entries = {};
    this.IV_all_active_conversations = ref({});
    this.IV_employees_active_conversations = ref({});

    this.IV_al_consumer_feedback_logs = {
      consumer_conversation_uid: 0,
      consumer_uid: 1,
      conversation_consumer_rating: 2,
      ma1_employee_feedback_messages_array: 3,
      ma1i_uid: 0,
      ma1i_type: 1,
      ma1i_content: 2,
      ma1i_timestamp: 3,
      ma1i_employee_uid: 4,
      ma1i_rating: 5
    };

    this.IV_consumer_feedback_logs = ref({});
    this.IV_consumer_contact_search_data = ref(JSON);
    this.IV_conversation_search_data = ref(JSON);

    this.IV_consumer_conversations = ref({});

    setInterval(this.updateConversationsStatusAndNewMessageCount.bind(this), 1000);

  }

  setInstanceSettings = (
    class_instance: bi2DataInteractions,
    vue_app_reference: Vue | undefined,
    user_firebase_uid: string,
    user_email: string,
    first_name: string,
    last_name: string
  ) => {
    const self = class_instance;
    self.populateListenerTypes(self);
    self.IV_vue_app = vue_app_reference;
    Vue.set(self.IV_loggedin_user_info, "email", user_email);
    Vue.set(self.IV_loggedin_user_info, "firebase_uid", user_firebase_uid);
    Vue.set(self.IV_loggedin_user_info, "first_name", first_name);
    Vue.set(self.IV_loggedin_user_info, "last_name", last_name);
    Vue.set(self.IV_loggedin_user_info, "display_name", `${first_name} ${last_name}`);

    this.IV_is_instance_ready = true;

    this.startRecyclerLocationInvitesListener(this);
  };

  loadInitialData(class_instance: bi2DataInteractions): Result {
    const self = class_instance;
    let result: Result = {
      debug_data: [],
      return_msg: "bi2DataInteractions:loadInitialData: ",
      success: true,
    };
    self.startLoggedInUserBusinessIdsListener(self,true);

    return result;
  }

  populateListenerTypes(
    class_instance:bi2DataInteractions 
    ): Result {
    let result: Result = {
      debug_data: [],
      return_msg: "bi2DataInteractions:populateListenerTypes: ",
      success: true,
    };

    const CI = class_instance;
    let loop1 = 1;
    let next_id = "";
    
    while(loop1 < 90) {
      next_id = "fs" + loop1.toString();
      CI.IV_schema_ids[next_id] = next_id;
      loop1 += 1;
    }

    return result;

  }

  registerListener(
    class_instance: bi2DataInteractions,
    document_type_list: any ,
    document_id_list: any,
    unsub_objecct: Unsubscribe): Result {
      const CI = class_instance;
      let result: Result = {
        debug_data: [],
        return_msg: "bi2DataInteractions:registerListener: ",
        success: true,
      };

      let parent_location = CI.IV_document_active_listeners;
      let loop1 = 0;
      let end_count = document_id_list.length -1;

      /// make sure the full path the listener needs exists
      while(loop1 <= end_count) {
        let id = document_id_list[loop1]
        let dtype = document_type_list[loop1];
        //create 
        if (dtype in parent_location === false) {
          parent_location[dtype] = {};
        }
        parent_location = parent_location[dtype];

        if (id in parent_location === false) {
          parent_location[id] = {};

        }
        parent_location = parent_location[id];
        loop1 += 1;
      }
      ///</end> make sure the full path the listener needs exists

      parent_location["listener"] = unsub_objecct;

      return result;

  }



  listenerExistsCheck(class_instance: bi2DataInteractions,
  document_type_list: any ,
  document_id_list: any): Result {
    const CI = class_instance;
    let result: Result = {
      debug_data: [],
      return_msg: "bi2DataInteractions:listenerExistsCheck: ",
      success: true,
      exists: false
    };

      result.exists = true;
      let parent_location = CI.IV_document_active_listeners;
      let loop1 = 0;
      let end_count = document_id_list.length -1;

      /// make sure the full path the listener needs exists
      while(loop1 <= end_count) {
        let id = document_id_list[loop1]
        let dtype = document_type_list[loop1];
        //create 
        if (dtype in parent_location === false) {
          result.exists = false;
          break;
        }
        parent_location = parent_location[dtype];

        if (id in parent_location === false) {
          result.exists = false;
          break;
        }
        parent_location = parent_location[id];
        loop1 += 1;
      }
      ///</end> make sure the full path the listener needs exists

      if ("collection" in parent_location === false && "listener" in parent_location === false) {
        result.exists = false;
      }


    return result;
  }


  startLoggedInUserBusinessIdsListener(
    class_instance: bi2DataInteractions,
    load_sub_collection = true
  ): Result {
    const self = class_instance;
    

    let result: Result = {
      debug_data: [],
      return_msg: "bi2DataInteractions:startLoggedInUserBusinessIdsListener: ",
      success: true,
    };

    let ids = self.IV_schema_ids;
    let call_result = self.listenerExistsCheck(self,
      [ids.fs29],
      [self.IV_loggedin_user_info.firebase_uid]
      )
    result.debug_data.push(call_result)
    if ( call_result.exists === true) {
      result.return_msg += `Listener already started for fs29.../${self.IV_loggedin_user_info.firebase_uid}`;
      result.success = true;
      return result;
    }

    // Generate reference
    const doc_ref = doc(
      self.IV_firebase_app,
      "fs29FirebaseAccounts",
      self.IV_loggedin_user_info.firebase_uid
    );

    const unsub = onSnapshot(doc_ref, (doc_snapshot) => {
      self.__fs29ListenerCallback(
        self,
        doc_snapshot.data() as fs29FirebaseAccounts,
        load_sub_collection
      );
    });

    call_result = self.registerListener(self,
      [ids.fs29],
      [self.IV_loggedin_user_info.firebase_uid],
      unsub
      )
    result.debug_data.push(call_result)

    return result;
  }

  __fs29ListenerCallback(
    class_instance: bi2DataInteractions,
    firestore_document: fs29FirebaseAccounts,
    load_sub_collection = true
  ): void {
    const self = class_instance;
    const firebase_account = firestore_document;

    self.IV_loggedin_user_business_ids.value =
      firebase_account?.business_uids_list != undefined
        ? firebase_account.business_uids_list
        : [];

    if (self.IV_loggedin_user_business_ids.value.length > 0) {
      self.IV_active_employee_info.business_uid = self.IV_loggedin_user_business_ids.value[0];
      self.IV_active_employee_info.is_business_owner = true;

      // Generate reference
      const collection_ref = collection(
        self.IV_firebase_app,
        "fs1RecyclerBusiness",
        self.IV_active_employee_info.business_uid,
        "fs2RecyclerLocation"
      );

      getDocs(collection_ref).then(documentRefs => {
        documentRefs.forEach((docRef) => {
          if (self.IV_active_employee_info.business_uid in self.IV_business_recycler_joins.value === false) {
            Vue.set(
              self.IV_business_recycler_joins.value, 
              self.IV_active_employee_info.business_uid,
              {}
            )
          }

          Vue.set(
            self.IV_business_recycler_joins.value[self.IV_active_employee_info.business_uid], 
            docRef.id,
            docRef.id
          )
        });
      });
    }

    this.IV_loggedin_user_business_ids_fetched = true;


    if (load_sub_collection) {
      self.startEmployeeAccountRecyclerJoinsListener(
        self,
        self.IV_loggedin_user_info.firebase_uid
      );
    }
  }

  startRecyclerBusinessListener(
    class_instance: bi2DataInteractions,
    recycler_business_uid: string,
    recycler_location_uid: string | null,
    load_sub_collection = true
  ): Result {
    const self = class_instance;
    
    let result: Result = {
      debug_data: [],
      return_msg: "bi2DataInteractions:startRecyclerBusinessListener: ",
      success: true,
    };

    //// start fs1 listener if its not already started
    let ids = self.IV_schema_ids;
    let call_result = self.listenerExistsCheck(self,
      [ids.fs1],
      [recycler_business_uid]
      )
    result.debug_data.push(call_result)
    if ( call_result.exists === true) {
      result.return_msg += `Listener already started for fs1.../${recycler_business_uid}`;
      result.success = true;
      return result;
    }
    

      const doc_ref = doc(
        class_instance.IV_firebase_app,
        "fs1RecyclerBusiness",
        recycler_business_uid
      );

      const unsub = onSnapshot(doc_ref, (doc_snapshot) => {
        self.__fs1ListenerCallback(
          self,
          doc_snapshot.data() as fs1RecyclerBusiness,
          recycler_business_uid,
          recycler_location_uid
        );
      });

        call_result = self.registerListener(self,[ids.fs1],
          [recycler_business_uid], unsub
        )
      result.debug_data.push(call_result)
    
    ////</end> start fs1 listener if its not already started


    return result;
  }

  __fs1ListenerCallback(
    class_instance: bi2DataInteractions,
    firestore_document: fs1RecyclerBusiness,
    recycler_business_uid: string,
    recycler_location_uid: string | null
  ): void {
    const self = class_instance;

    Vue.set(self.IV_businesses.value, recycler_business_uid, {
      name: firestore_document.business_name,
      phone_number: firestore_document?.phone,
      owner_email: firestore_document?.owner_email,
      is_archived: firestore_document?.is_archived,
      business_type: firestore_document?.business_type,
    });

    self.startPaymentMethodListener(
      self,
      recycler_business_uid,
      "1"
    )

    // if a recycler location was specified load it
    if (recycler_location_uid !== undefined && recycler_location_uid !== null) 
    {
      self.startRecyclerLocationListener(
        self,
        recycler_business_uid,
        recycler_location_uid
      );
    }
  }

  startRecyclerLocationListener(
    class_instance: bi2DataInteractions,
    recycler_business_uid: string,
    recycler_location_uid: string,
    load_sub_collection = true
  ): Result {
    const self = class_instance;
    
    let result: Result = {
      debug_data: [],
      return_msg: "bi2DataInteractions:startRecyclerLocationListener: ",
      success: true,
    };

    let ids = self.IV_schema_ids;
    let call_result = self.listenerExistsCheck(self,
      [ids.fs1,ids.fs2],
      [recycler_business_uid,recycler_location_uid]
      )
      
    result.debug_data.push(call_result);
    if (call_result.exists === true) {
      result.return_msg += `Listener already started for fs1.../${recycler_business_uid}/fs2.../${recycler_location_uid}`;
      result.success = true;
      return result;
    }

    const doc_ref = doc(
      self.IV_firebase_app,
      "fs1RecyclerBusiness",
      recycler_business_uid,
      "fs2RecyclerLocation",
      recycler_location_uid
    );

    const unsub = onSnapshot(doc_ref, (doc_snapshot) => {
      if (doc_snapshot.data()) {
        self.__fs2DocumentListenerCallback(
          self,
          doc_snapshot.data() as fs2RecyclerLocation,
          recycler_business_uid,
          load_sub_collection
        );
      }
    });

    call_result = self.registerListener(self,
      [ids.fs1,ids.fs2],
      [recycler_business_uid,recycler_location_uid],
      unsub
      )
    result.debug_data.push(call_result);

    return result;
  }

  __fs2DocumentListenerCallback(
    class_instance: bi2DataInteractions,
    firestore_document: fs2RecyclerLocation,
    recycler_business_uid: string,
    load_sub_collection = true
  ): void {
    const self = class_instance;
    const doc_data = firestore_document;

    Vue.set(self.IV_recyclers.value, firestore_document.recycler_location_uid, {
      // from: fs1
      recycler_business_uid: recycler_business_uid,
      //TODO: apply changes here once fs1 is loaded
      recycler_business_name:
        self.IV_businesses.value[recycler_business_uid]?.name,
      // from: fs2
      recycler_location_name: doc_data.recycler_name,
      phone_number: doc_data.phone,
      text_number: doc_data.text_number,
      text_number_sid: doc_data.text_number_sid,
      email: doc_data.email,
      city: doc_data.city,
      state: doc_data.state,
      postal_code: doc_data.postal_code,
      address_line_1: doc_data.address_line_1,
      address_line_2: doc_data.address_line_2,
      website: doc_data.website,
      google_maps_url: doc_data.google_maps_url,
      facebook_url: doc_data.facebook_url,
      payment_api_keys_lookup_uid: doc_data.payment_api_keys_lookup_uid,
      review_provider_account_uid: doc_data.review_provider_account_uid || null,
      assigned_payment_method: doc_data.assigned_payment_method || null,
      sms_only_messaging: doc_data.sms_only_messaging || false,
      connect_account_id: doc_data.connect_account_id || null,
      conversation_starters_enabled: doc_data.conversation_starters_enabled || false,
      timezone: doc_data.timezone || null,
      days_open: doc_data.days_open || null,
      business_hours: doc_data.business_hours || null,
      autosend_review_link_url: doc_data.autosend_review_link_url || false,
      after_hours_message: doc_data.after_hours_message || null,
      quick_queue_entry: doc_data.quick_queue_entry || false,
    });

    const fs109Ref = doc(
      self.IV_firebase_app,
      "fs1RecyclerBusiness",
      recycler_business_uid,
      "fs2RecyclerLocation",
      firestore_document.recycler_location_uid,
      "fs109StripeIdentityVerificationPlans",
      firestore_document.recycler_location_uid
    );

    const unsub = onSnapshot(fs109Ref, (doc_snapshot) => {
      if (doc_snapshot.exists() && doc_snapshot.data()) {
        const fs109_data = doc_snapshot.data();
        Vue.set(
          self.IV_stripe_identity_verification.value, 
          firestore_document.recycler_location_uid,
          fs109_data
        )
      }
    });

    // when loading sub-collection is not enabled skip sub-collection loading
    if (!load_sub_collection) {
      return;
    }

    self.startRecyclerLocationEmployeeListener(
      self,
      recycler_business_uid,
      doc_data.recycler_location_uid
    );

    // Starting Converstaion Queue Listener
    self.startConversationQueueListener(
      self,
      recycler_business_uid,
      doc_data.recycler_location_uid
    );

    // Starting transactions terms and conditions listener
    self.startTransactionTermsAndConditionsListener(
      self,
      recycler_business_uid,
      doc_data.recycler_location_uid
    );

    // Start Recent Queue Entries listener
    self.startRecentQueueEntriesListener(
      self,
      recycler_business_uid,
      doc_data.recycler_location_uid
    );

    // Start Recent Removed Queue Entries listener
    self.startRecentRemovedQueueEntriesListener(
      self,
      recycler_business_uid,
      doc_data.recycler_location_uid
    );

    self.startActiveConsumerConversationJoinsListener(
      self,
      recycler_business_uid,
      doc_data.recycler_location_uid
    );

    self.startBusinessCategoriesListner(
      self,
      recycler_business_uid,
      doc_data.recycler_location_uid
    );

    self.startYardOnlyChatInvitesListener(
      self,
      recycler_business_uid,
      doc_data.recycler_location_uid,
      self.IV_active_employee_info.employee_uid
    );
/** 
    self.startConversationNotificationsListener(
      self,
      recycler_business_uid,
      doc_data.recycler_location_uid
    );

    self.startReadNotificationsListener(
      self,
      recycler_business_uid,
      doc_data.recycler_location_uid,
      self.IV_active_employee_info.employee_uid
    );
*/
    self.startConsumerBusinessesListener(
      self,
      recycler_business_uid,
      doc_data.recycler_location_uid
    );
  }

  startPaymentMethodListener(
    class_instance: bi2DataInteractions,
    recycler_business_uid: string,
    billing_method_uid: string,
    load_sub_collection = true
  ): Result {
    const self = class_instance;
    
    let result: Result = {
      debug_data: [],
      return_msg: "bi2DataInteractions:startPaymentMethodListener: ",
      success: true,
    };

    let ids = self.IV_schema_ids;
    let call_result = self.listenerExistsCheck(self,
      [ids.fs1,ids.fs14],
      [recycler_business_uid,billing_method_uid]
    )

    result.debug_data.push(call_result);
    if (call_result.exists === true) {
      result.return_msg += `Listener already started for fs1.../${recycler_business_uid}/fs14.../${billing_method_uid}`;
      result.success = true;
      return result;
    }

    const doc_ref = doc(
      self.IV_firebase_app,
      "fs1RecyclerBusiness",
      recycler_business_uid,
      "fs14PaymentMethod",
      billing_method_uid
    );

    const unsub = onSnapshot(doc_ref, (doc_snapshot) => {
      if (doc_snapshot.data()) {
        self.__f14DocumentListenerCallback(
          self,
          doc_snapshot.data() as fs14PaymentMethod,
          billing_method_uid,
          load_sub_collection
        );
      }
    });

    call_result = self.registerListener(self,
      [ids.fs1,ids.fs14],
      [recycler_business_uid,billing_method_uid],
      unsub
      )
    result.debug_data.push(call_result);

    return result;
  }

  __f14DocumentListenerCallback(
    class_instance: bi2DataInteractions,
    firestore_document: fs14PaymentMethod,
    billing_method_uid: string,
    load_sub_collection = true
  ): void {
    const self = class_instance;
    const doc_data = firestore_document;

    Vue.set(self.IV_payment_method.value, billing_method_uid, {
      customer_id: doc_data.customer_id,
      creation_day: doc_data.creation_day,
      card_type: doc_data.card_type,
      last_4_digits: doc_data.last_4_digits,
      payment_method_status: doc_data.payment_method_status
    });
  }  

  //TODO add to SDB
  startYardOnlyChatInvitesListener(
    class_instance: bi2DataInteractions,
    recycler_business_uid: string,
    recycler_location_uid: string,
    employee_uid: string,
  ): Result {
    const self = class_instance;
    
    let result: Result = {
      debug_data: [],
      return_msg: "bi2DataInteractions:startYardOnlyChatInvitesListener: ",
      success: true,
    };

    let ids = self.IV_schema_ids;
    let call_result = self.listenerExistsCheck(self,
      [ids.fs1,ids.fs2,ids.fs73],
      [recycler_business_uid,recycler_location_uid,employee_uid]
      )      
    result.debug_data.push(call_result);
    if (call_result.exists === true) {
      result.return_msg += `Listener already started for fs1.../${recycler_business_uid}/fs2.../${recycler_location_uid}/fs39 colllection`;
      result.success = true;
      return result;
    }

    const query = collection(
      self.IV_firebase_app,
      `fs1RecyclerBusiness/${recycler_business_uid}/fs2RecyclerLocation/${recycler_location_uid}/fs72EmployeeConversationInvitesFolder/${employee_uid}/fs73EmployeeConversationInviteFolderEntries/`
    );


    const unsub = onSnapshot(query, (doc_snapshots) => {
      doc_snapshots.docChanges().forEach((change) => {
        const doc_data = change.doc.data() as fs73EmployeeConversationInviteFolderEntries;
        self.__fs73ListenerCallback(
          self,
          doc_data,
          recycler_business_uid,
          recycler_location_uid,
          employee_uid
        );
      });
    });

    call_result = self.registerListener(self,
      [ids.fs1,ids.fs2,ids.fs73],
      [recycler_business_uid,recycler_location_uid,employee_uid],
      unsub
      )
    result.debug_data.push(call_result);


    return result;
  }

  __fs73ListenerCallback(
    class_instance: bi2DataInteractions,
    firestore_document: fs73EmployeeConversationInviteFolderEntries,
    recycler_business_uid: string,
    recycler_location_uid: string,
    employee_uid: string
  ): void {
    const CI = class_instance;
    const doc_data = firestore_document;
    let conversation_uuid = doc_data.consumer_uid + "-" + doc_data.conversation_uid
    Vue.set(
      CI.IV_yard_side_chat_invites.value,
      conversation_uuid,
      {
        consumer_uid: doc_data.consumer_uid,
        conversation_uid: doc_data.conversation_uid,
        invited_timestamp: doc_data.invited_timestamp,
        inviting_employee_uid: doc_data.inviting_employee_uid,
        last_response: doc_data.last_response,
        response_timestamp: doc_data.response_timestamp
      }
    );

    //FIXME we need to handle not loading finished conversations someone was inivted
    //if we have an invite to this conversation start a listener on it
    if (doc_data.last_response != 2) { return; }
    CI.startConsumerConversationListener(CI,
      recycler_business_uid,
      recycler_location_uid,
      doc_data.consumer_uid,
      doc_data.conversation_uid
    );
  }

  //TODO add to SDB
  startBusinessCategoriesListner(
    class_instance: bi2DataInteractions,
    recycler_business_uid: string,
    recycler_location_uid: string,
    load_sub_collection = true
  ): Result {
    const self = class_instance;
    
    let result: Result = {
      debug_data: [],
      return_msg: "bi2DataInteractions:startBusinessCategoriesListner: ",
      success: true,
    };

    let ids = self.IV_schema_ids;
    let call_result = self.listenerExistsCheck(self,
      [ids.fs1,ids.fs2,ids.fs39],
      [recycler_business_uid,recycler_location_uid,"collection"]
      )      
    result.debug_data.push(call_result);
    if (call_result.exists === true) {
      result.return_msg += `Listener already started for fs1.../${recycler_business_uid}/fs2.../${recycler_location_uid}/fs39 colllection`;
      result.success = true;
      return result;
    }

    const query = collection(
      self.IV_firebase_app,
      `fs1RecyclerBusiness/${recycler_business_uid}/fs2RecyclerLocation/${recycler_location_uid}/fs39ConsumerBusinessesCategories`
    );


    const unsub = onSnapshot(query, (doc_snapshots) => {
      doc_snapshots.forEach((doc_snapshot) => {
        const doc_data = doc_snapshot.data() as fs39ConsumerBusinessesCategories;
        self.__fs39ListenerCallback(
          self,
          doc_data,
          recycler_business_uid,
          recycler_location_uid
        );
      });
    });

    call_result = self.registerListener(self,
      [ids.fs1,ids.fs2,ids.fs39],
      [recycler_business_uid,recycler_location_uid,"collection"],
      unsub
    )
    
    result.debug_data.push(call_result);
    return result;
  }
  
  __fs39ListenerCallback(
    class_instance: bi2DataInteractions,
    firestore_document: fs39ConsumerBusinessesCategories,
    recycler_business_uid: string,
    recycler_location_uid: string,
    load_sub_collection = true
  ): void {
    const self = class_instance;
    //from: fs37
    const consumer_business_category_doc = firestore_document;

    Vue.set(self.IV_consumer_business_types.value, recycler_location_uid, {
      //from: fs39
      [consumer_business_category_doc.consumer_business_category_uid]: {
        uid: consumer_business_category_doc.consumer_business_category_uid,
        name: consumer_business_category_doc.name,
        description: consumer_business_category_doc.description,
      },
    });

  }

  startLoggedInUserEmployeeDataPerLocationListener(
    class_instance: bi2DataInteractions,
    recycler_business_uid: string,
    recycler_location_uid: string,
    employee_uid: string,
    load_sub_collection = true
  ): Result {
    const self = class_instance;
    
    let result: Result = {
      debug_data: [],
      return_msg:
        "bi2DataInteractions:startLoggedInUserEmployeeDataPerLocationListener: ",
      success: true,
    };

    let ids = self.IV_schema_ids;
    let call_result = self.listenerExistsCheck(self,
      [ids.fs1,ids.fs2,ids.fs3],
      [recycler_business_uid,recycler_location_uid,employee_uid]
      )      
    result.debug_data.push(call_result);
    if (call_result.exists === true) {
      result.return_msg += `Listener already started for LoggedInUserEmployeeDataPerRecyclerLocation fs1.../${recycler_business_uid}/fs2.../${recycler_location_uid}/fs3.../${employee_uid}`;
      result.success = true;
      return result;
    }

    const doc_ref = doc(
      self.IV_firebase_app,
      "fs1RecyclerBusiness",
      recycler_business_uid,
      "fs2RecyclerLocation",
      recycler_location_uid,
      "fs3Employees",
      employee_uid
    );

    const unsub = onSnapshot(doc_ref, (doc_snapshot) => {
      self.__loggedInUserEmployeeDataPerLocationCallback(
        self,
        doc_snapshot.data() as fs3Employees,
        recycler_business_uid,
        recycler_location_uid,
        employee_uid
      );
    });

    call_result = self.registerListener(self,
      [ids.fs1,ids.fs2,ids.fs3],
      [recycler_business_uid,recycler_location_uid,employee_uid],
      unsub
      )      
    result.debug_data.push(call_result);

    return result;
  }

  __loggedInUserEmployeeDataPerLocationCallback(
    class_instance: bi2DataInteractions,
    firestore_document: fs3Employees,
    recycler_business_uid: string,
    recycler_location_uid: string,
    employee_uid: string,
    load_sub_collection = true
  ): void {
    const self = class_instance;
    const doc = firestore_document;

    Vue.set(
      self.IV_logged_in_user_employee_data_per_location.value,
      recycler_location_uid,
      {
        first_name: doc.first_name,
        last_name: doc.last_name,
        email: doc.email_address,
        phone_number: doc.phone_number,
      }
    );
  }

  startRecyclerLocationEmployeeListener(
    class_instance: bi2DataInteractions,
    recycler_business_uid: string,
    recycler_location_uid: string,
    // employee_uid: string,
    load_sub_collection = true
  ): Result {
    const self = class_instance;
    
    let result: Result = {
      debug_data: [],
      return_msg: "bi2DataInteractions:startRecyclerLocationEmployeeListener: ",
      success: true,
    };

    let ids = self.IV_schema_ids;
    let call_result = self.listenerExistsCheck(self,
      [ids.fs1,ids.fs2,ids.fs3],
      [recycler_business_uid,recycler_location_uid,"collection"]
      )
    result.debug_data.push(call_result);

    if (call_result.exists === true) {
      result.return_msg += `Listener already started for fs1.../${recycler_business_uid}/fs2.../${recycler_location_uid}/fs3Employees...`;
      result.success = true;
      return result;
    }
    const query = collection(
      self.IV_firebase_app,
      `fs1RecyclerBusiness/${recycler_business_uid}/fs2RecyclerLocation/${recycler_location_uid}/fs3Employees`
    );

    const unsub = onSnapshot(query, (doc_snapshots) => {
      doc_snapshots.docChanges().forEach((doc_snapshot) => {
        self.__fs3DocumentListenerCallback(
          self,
          doc_snapshot.doc.data() as fs3Employees,
          recycler_business_uid,
          recycler_location_uid
        );
      });
    });

    call_result = self.registerListener(self,
      [ids.fs1,ids.fs2,ids.fs3],
      [recycler_business_uid,recycler_location_uid,"collection"],
      unsub)
    result.debug_data.push(call_result);

    return result;
  }

  __fs3DocumentListenerCallback(
    class_instance: bi2DataInteractions,
    firestore_document: fs3Employees,
    recycler_business_uid: string,
    recycler_location_uid: string,
  ): void {
    const self = class_instance;
    const doc_data = firestore_document;

    // This is the case when a new records created without auto generated employee_uid
    if (!doc_data.employee_uid) { return; }

    const data_to_set = {
      employee_uid: doc_data.employee_uid,
      first_name: doc_data.first_name,
      last_name: doc_data.last_name,
      email_address: doc_data.email_address,
      phone_number: doc_data.phone_number,
      average_employee_rating: doc_data.average_employee_rating,
      extension: doc_data.extension,
      is_deactivated: doc_data.is_deactivated || false,
      permissions: {
        admin: doc_data.permissions.admin || false,
        sales_person: doc_data.permissions.sales_person || false,
        manager: doc_data.permissions.manager || false,
        report_viewer: doc_data.permissions.report_viewer || false,
      },
    };

    if (self.IV_active_employee_info.employee_uid === doc_data.employee_uid) {
       //@ts-ignore: typescript doesn't handle dynamic nested lookup dictionaries well
      self.IV_active_employee_info.permissions = data_to_set.permissions;
    }

    Vue.set(
      self.IV_recycler_employees.value,
      doc_data.employee_uid,
      data_to_set
    );

  }

  startAllConsumerContactsListener(
    class_instance: bi2DataInteractions,
    recycler_business_uid: string,
    recycler_location_uid: string
  ): Result {
    const self = class_instance;

    let result: Result = {
      debug_data: [],
      return_msg: "bi2DataInteractions:startAllConsumerContactsListener: ",
      success: true,
    };

    let ids = self.IV_schema_ids;
    let call_result = self.listenerExistsCheck(self,
      [ids.fs1,ids.fs2,ids.fs4],
      [recycler_business_uid,recycler_location_uid,"collection"]
      )
    result.debug_data.push(call_result);

    if (call_result.exists === true) {
      result.return_msg += `Listener already started for fs1.../${recycler_business_uid}/fs2.../${recycler_location_uid}/fs4...`;
      result.success = true;
      return result;
    }

    const query = collection(
      self.IV_firebase_app,
      "fs1RecyclerBusiness",
      recycler_business_uid,
      "fs2RecyclerLocation",
      recycler_location_uid,
      "fs4ConsumerContacts"
    );

    const unsub = onSnapshot(query, (doc_snapshots) => {
      doc_snapshots.docChanges().forEach((change) => {
        const doc_data = change.doc.data() as fs4ConsumerContacts;
        self.__fs4CollectionListenerCallback(
          self,
          doc_data,
          recycler_business_uid,
          recycler_location_uid
        );
      });
    });

    call_result = self.registerListener(self,
      [ids.fs1,ids.fs2,ids.fs4],
      [recycler_business_uid,recycler_location_uid,"collection"],
      unsub
      )
    result.debug_data.push(call_result);

    return result;
  }

  startConsumerContactListener(
    class_instance: bi2DataInteractions,
    recycler_business_uid: string,
    recycler_location_uid: string,
    consumer_uid: string,
    load_conversation_history =false,
    load_conversation_uid = ""
  ): Result {
    const self = class_instance;
    

    let result: Result = {
      debug_data: [],
      return_msg: "bi2DataInteractions:startConsumerContactListener: ",
      success: true,
    };

    let ids = self.IV_schema_ids;
    let ids_list = [ids.fs1,ids.fs2,ids.fs4];
    let doc_uids_list =[recycler_business_uid,recycler_location_uid,consumer_uid];
    if (load_conversation_history === true) {
      ids_list.push(ids.fs6);
      doc_uids_list.push("collection");
    } else if (load_conversation_uid !== "") {
      ids_list.push(ids.fs6);
      doc_uids_list.push(load_conversation_uid);
    } 
    let call_result = self.listenerExistsCheck(self,
      ids_list,
      doc_uids_list
      )
    result.debug_data.push(call_result);

    if (call_result.exists === true) {
      result.return_msg += `Listener already started for fs1.../${recycler_business_uid}/fs2.../${recycler_location_uid}/fs4.../${consumer_uid}`;
      result.success = true;
      return result;
    }

    const fs4_doc_ref = doc(
      self.IV_firebase_app,
      "fs1RecyclerBusiness",
      recycler_business_uid,
      "fs2RecyclerLocation",
      recycler_location_uid,
      "fs4ConsumerContacts",
      consumer_uid
    );

    const unsub = onSnapshot(fs4_doc_ref, (doc_snapshot) => {
      const doc_data = doc_snapshot.data() as fs4ConsumerContacts;  
      self.__fs4CollectionListenerCallback(
        self,
        doc_data,
        recycler_business_uid,
        recycler_location_uid,
        load_conversation_history,
        load_conversation_uid
      );
  });


    call_result = self.registerListener(self,
      [ids.fs1,ids.fs2,ids.fs4],
      [recycler_business_uid,recycler_location_uid,consumer_uid],
      unsub
      )
    result.debug_data.push(call_result);

    return result;
  }


  //TODO SBD
  findConsumerByPhoneNumber(class_instance: bi2DataInteractions, phone_number: string): Result {
    const CI = class_instance;
    
    let result: Result = {
      debug_data: [],
      return_msg: "bi2DataInteractions:findConsumerByPhoneNumber: ",
      success: true,
      consumer_uid: "",
    };

    let lookup = CI.IV_consumer_phone_lookup.value;
    let loop_count = 0;
    if(phone_number.length < 10) { return result;}
    
    while (loop_count <= 8) {
      let letter = phone_number[loop_count].toString();
      
      if (loop_count === 8 && phone_number in lookup === true) {
        //@ts-ignore: typescript doesn't handle dynamic nested lookup dictionaries well
        result.consumer_uid = lookup[phone_number]
        return result;
      }
      
      if (letter in lookup === false) { break; }
      
      //@ts-ignore: typescript doesn't handle dynamic nested lookup dictionaries well
      lookup = lookup[letter];
      loop_count += 1;
    }

    return result;
  }

  //TODO SBD 
  addPhoneToSearchLookup(class_instance: bi2DataInteractions, phone_number: string,consumer_uid: string) {
    const CI = class_instance;

    //aliases for readability
    let lookup = CI.IV_consumer_phone_lookup.value;

    let loop_count = 0;

    while (loop_count <= 8) {
      let letter = phone_number[loop_count].toString();
      
      // set the full phone number value when we get to the last search index
      if (loop_count === 8) {
        Vue.set(lookup, phone_number, consumer_uid);
        break;
      }

      if (letter in lookup === false) {
        Vue.set(lookup, letter, {});
      }
      //overwrite lookup with the next sub-structre we need to make is set
      //@ts-ignore: typescript doesn't handle dynamic nested lookup dictionaries well
      lookup = lookup[letter];

      loop_count += 1;
    }
  }

  __fs4CollectionListenerCallback(
    class_instance: bi2DataInteractions,
    firestore_document: fs4ConsumerContacts,
    recycler_business_uid: string,
    recycler_location_uid: string,
    load_conversation_history = false,
    load_specific_conversation_uid = ""
  ): void {
    const self = class_instance; 
    const fs4_doc = firestore_document;
    
    if (fs4_doc == undefined) { return; }
    if (!fs4_doc.consumer_contact_uid) { return; }

    Vue.set(self.IV_consumer_contacts.value, fs4_doc.consumer_contact_uid, [
      fs4_doc.consumer_contact_uid,
      fs4_doc.first_name,
      fs4_doc.last_name,
      fs4_doc.email,
      fs4_doc.firebase_account_uid,
      fs4_doc.landline_phone_numbers ? fs4_doc.landline_phone_numbers : {},
      fs4_doc.average_consumer_rating,
      fs4_doc.average_employee_rating,
      [
        fs4_doc.address_info?.city,
        fs4_doc.address_info?.state,
        fs4_doc.address_info?.postal_code,
        fs4_doc.address_info?.address_line_1,
        fs4_doc.address_info?.address_line_2,
      ],
      [], // consumer_businesses_uids
      fs4_doc.assigned_employees != undefined ? fs4_doc.assigned_employees : [],
      fs4_doc.facebook_profile_url,
      {
        phone_type: fs4_doc.phone_number?.phone_type,
        phone_number: fs4_doc.phone_number?.phone_number,
        extension: fs4_doc.phone_number?.extension,
        description: fs4_doc.phone_number?.description,
      },
      fs4_doc.customer_types ? fs4_doc.customer_types : [],
      fs4_doc.is_deactivated || false,
      fs4_doc.verified_outputs ? fs4_doc.verified_outputs : {},
      [
        fs4_doc.shipping_address_info?.city,
        fs4_doc.shipping_address_info?.state,
        fs4_doc.shipping_address_info?.postal_code,
        fs4_doc.shipping_address_info?.address_line_1,
        fs4_doc.shipping_address_info?.address_line_2,
      ],
    ]);

    if (fs4_doc.phone_number?.phone_number) {
      this.addPhoneToSearchLookup(
        self, 
        fs4_doc.phone_number.phone_number,
        fs4_doc.consumer_contact_uid
      );
    }

    if (fs4_doc.landline_phone_numbers) {
      const landline_numbers = fs4_doc.landline_phone_numbers || {}
      
      for (let number in landline_numbers) {
        this.addPhoneToSearchLookup(
          self, 
          number,
          fs4_doc.consumer_contact_uid
        );        
      }
    }
/** 
    self.startConsumerConversationHistoryIndexListener(
      self,
      recycler_business_uid,
      recycler_location_uid,
      fs4_doc.consumer_contact_uid
    );
*/
    if ( load_specific_conversation_uid !== "") {
      self.startConsumerConversationListener(self,
        recycler_business_uid,recycler_location_uid,
        fs4_doc.consumer_contact_uid,load_specific_conversation_uid,
        true,false,false,true,true);      
    }

    self.IV_consumer_contacts_data_last_changed.value = Date.now();
    

  }

  startConsumerConversationListener(
    class_instance: bi2DataInteractions,
    recycler_business_uid: string,
    recycler_location_uid: string,
    consumer_uid: string,
    conversation_uid: string,
    load_consumer_messages = false,
    load_feedback_log = false,
    load_employee_chat = false,
    load_payment_links = false,
    load_consumer_vehicle = false    
  ): Result {
    const self = class_instance;
    
    let result: Result = {
      debug_data: [],
      return_msg: "bi2DataInteractions:startConsumerContactListener: ",
      success: true,
    };
  
    let ids = self.IV_schema_ids;
    let call_result = self.listenerExistsCheck(
      self,
      [ids.fs1, ids.fs2, ids.fs4, ids.fs6],
      [recycler_business_uid, recycler_location_uid, consumer_uid, conversation_uid]
    );
    result.debug_data.push(call_result);
  
    if (call_result.exists === true && (`${consumer_uid}-${conversation_uid}` in self.IV_yard_side_chat_invites.value == false)) {
      result.return_msg += `Listener already started for fs1.../${recycler_business_uid}/fs2.../${recycler_location_uid}/fs4.../${consumer_uid}/fs6../${conversation_uid}`;
      result.success = true;
      return result;
    }
  /**
    const fs4_col_ref = collection(
      self.IV_firebase_app, 
      `fs1RecyclerBusiness/${recycler_business_uid}/fs2RecyclerLocation/${recycler_location_uid}/fs4ConsumerContacts/${consumer_uid}/fs6ConsumerConversations`
    );
   */
    const fs6_col_group = collectionGroup(
      self.IV_firebase_app,
      "fs6ConsumerConversations"
    )
    // Query for active conversations
    const active_conversations_query = query(
      fs6_col_group, 
      where("conversation_status_codes", "==", "b")
    );
  
    // Listener for active conversations (priority)
    const unsubActive = onSnapshot(active_conversations_query, (doc_snapshots) => {
      doc_snapshots.forEach((doc_snapshot) => {
        const path = doc_snapshot.ref.path;
   
        // Check if the path matches the required structure
        if (
          path.includes(`fs1RecyclerBusiness/${recycler_business_uid}/`) &&
          path.includes(`/fs2RecyclerLocation/${recycler_location_uid}/`) &&
          path.includes(`/fs4ConsumerContacts/${consumer_uid}/`)
        ) {
          const doc_data = doc_snapshot.data() as fs6ConsumerConversations;
          self.__fs6ListenerCallback(
            self,
            doc_data,
            recycler_business_uid,
            recycler_location_uid,
            consumer_uid,
            load_consumer_messages,
            load_feedback_log,
            load_employee_chat,
            load_payment_links,
            load_consumer_vehicle
          );
      }
      });
    });

    call_result = self.registerListener(
      self,
      [ids.fs1, ids.fs2, ids.fs4, ids.fs6],
      [recycler_business_uid, recycler_location_uid, consumer_uid, conversation_uid],
      unsubActive
    );
    result.debug_data.push(call_result);
    
    // Query for queued conversations
    const queued_conversations_query = query(
      fs6_col_group, 
      where("conversation_status_codes", "==", "a"),
    );
  
    // Listener for queued conversations (secondary)
    const unsubQueued = onSnapshot(queued_conversations_query, (doc_snapshots) => {
      doc_snapshots.forEach((doc_snapshot) => {
        const path = doc_snapshot.ref.path;
    
        // Check if the path matches the required structure
        if (
          path.includes(`fs1RecyclerBusiness/${recycler_business_uid}/`) &&
          path.includes(`/fs2RecyclerLocation/${recycler_location_uid}/`) &&
          path.includes(`/fs4ConsumerContacts/${consumer_uid}/`)
        ) {
          const doc_data = doc_snapshot.data() as fs6ConsumerConversations;
          self.__fs6ListenerCallback(
            self,
            doc_data,
            recycler_business_uid,
            recycler_location_uid,
            consumer_uid,
            load_consumer_messages,
            load_feedback_log,
            load_employee_chat,
            load_payment_links,
            load_consumer_vehicle
          );
        }
      });
    });
   
    call_result = self.registerListener(
      self,
      [ids.fs1, ids.fs2, ids.fs4, ids.fs6],
      [recycler_business_uid, recycler_location_uid, consumer_uid, conversation_uid],
      unsubQueued
    );
    result.debug_data.push(call_result);
  
    return result;
  }

  startConversationQueueListener(
    class_instance: bi2DataInteractions,
    recycler_business_uid: string,
    recycler_location_uid: string,
    load_sub_collection = true
  ): Result {
    const self = class_instance;
    
    let result: Result = {
      debug_data: [],
      return_msg: "bi2DataInteractions:startConversationQueueListener: ",
      success: true,
    };

    const query = collection(
      self.IV_firebase_app,
      `fs1RecyclerBusiness/${recycler_business_uid}/fs2RecyclerLocation/${recycler_location_uid}/fs27ConsumerConversationQueue`
    );

    let ids = self.IV_schema_ids;
    let call_result = self.listenerExistsCheck(self,
      [ids.fs1,ids.fs2,ids.fs27],
      [recycler_business_uid,recycler_location_uid,"collection"]
      )
    result.debug_data.push(call_result);
    if (call_result.exists === true) {
      result.return_msg += `Listener already started for fs1.../${recycler_business_uid}/fs2.../${recycler_location_uid}/fs27ConsumerConversationQueue`;
      result.success = true;
      return result;
    }
    // here we are listening on whole conversation queue for a recycler location
    const unsub = onSnapshot(query, (doc_snapshots) => {
      doc_snapshots.forEach((doc_snapshot) => {
        const doc_data = doc_snapshot.data() as fs27ConsumerConversationQueue;
        self.__fs27CollectionListenerCallback(
          self,
          doc_data,
          recycler_business_uid,
          recycler_location_uid
        );
      });
    });

    call_result = self.registerListener(self,
      [ids.fs1,ids.fs2,ids.fs27],
      [recycler_business_uid,recycler_location_uid,"collection"],
      unsub
      )
    result.debug_data.push(call_result);

    return result;
  }

  async __fs27CollectionListenerCallback(
    class_instance: bi2DataInteractions,
    firestore_document: fs27ConsumerConversationQueue,
    recycler_business_uid: string,
    recycler_location_uid: string,
    load_sub_collection = true
  ): Promise<void> {
    const self = class_instance;
    const doc_data = firestore_document;
    

    //// make sure a listener exists for both the consumer and the conversation uid in the queue
    let fs4_listener_exists:any = false;
    let fs6_listener_exists:any = false;
    let ids = self.IV_schema_ids;
    let call_result = self.listenerExistsCheck(self,
      [ids.fs1,ids.fs2,ids.fs4],
      [recycler_business_uid,recycler_location_uid,doc_data.consumer_contact_uid]
      )
    fs4_listener_exists = call_result.exists;

    call_result = self.listenerExistsCheck(self,
      [ids.fs1,ids.fs2,ids.fs4,ids.fs6],
      [recycler_business_uid,recycler_location_uid,doc_data.consumer_contact_uid,doc_data.conversation_uid]
      )
      fs6_listener_exists = call_result.exists;
   ////</end> make sure a listener exists for both the consumer and the conversation uid in the queue


    if (fs4_listener_exists === true && fs6_listener_exists === true) {
      return;
    }

    //start a listener for both the consumer and the conversation
    if (fs4_listener_exists === false){
      self.startConsumerContactListener(self,recycler_business_uid,
        recycler_location_uid,doc_data.consumer_contact_uid,true,doc_data.conversation_uid);
        return;
    } else if (fs6_listener_exists === false){
      self.startConsumerContactListener(self,recycler_business_uid,
        recycler_location_uid,doc_data.consumer_contact_uid,false,doc_data.conversation_uid);
    }

    ////</end> start a listener on the conversation if it hasn't already been started

  }

  startRecyclerConversationStartersListener(
    class_instance: bi2DataInteractions,
    recycler_business_uid: string,
    recycler_location_uid: string,
  ): Result {
    const self = class_instance;

    let result: Result = {
      debug_data: [],
      return_msg: "bi2DataInteractions:startRecyclerConversationStartersListener: ",
      success: true,
    };

    let ids = self.IV_schema_ids;
    let call_result = self.listenerExistsCheck(self,
      [ids.fs1,ids.fs2,ids.fs139],
      [recycler_business_uid,recycler_location_uid, "fs139RecyclerConversationStarters"]
    )
    result.debug_data.push(call_result);

    if (call_result.exists === true) {
      result.return_msg += `Listener already started for fs1.../${recycler_business_uid}/fs2.../${recycler_location_uid}/fs139`;
      result.success = true;
      return result;
    }

    const query = collection(
      self.IV_firebase_app,
      "fs1RecyclerBusiness",
      recycler_business_uid,
      "fs2RecyclerLocation",
      recycler_location_uid,
      "fs139RecyclerConversationStarters",
    );

    const unsub = onSnapshot(query, (doc_snapshots) => {
      doc_snapshots.docChanges().forEach((change) => {
        const fs139_doc_id = change.doc.id;
        const doc_data = change.doc.data() as fs139RecyclerConversationStarters;
        self.__fs139RecyclerConversationStartersCallback(
          self,
          doc_data,
          fs139_doc_id
        );
      });
    });

    call_result = self.registerListener(self,
      [ids.fs1,ids.fs2,ids.fs138],
      [recycler_business_uid,recycler_location_uid,"collection"],
      unsub
    )
    result.debug_data.push(call_result);

    return result;
  }

  __fs139RecyclerConversationStartersCallback(
    class_instance: bi2DataInteractions,
    firestore_document: fs139RecyclerConversationStarters,
    fs139_doc_id: string
  ): void {
    const self = class_instance;
    const doc_data = firestore_document;

    if (!doc_data) { return; }

    Vue.set(
      self.IV_recycler_conversation_starters.value, 
      fs139_doc_id, {
        id: fs139_doc_id,
        subject: doc_data.subject,
        body: doc_data.body,
      }
    )
  }

  startAVSverificationPlansListener(
    class_instance: bi2DataInteractions,
    recycler_business_uid: string,
    recycler_location_uid: string,
  ): Result {
    const self = class_instance;

    let result: Result = {
      debug_data: [],
      return_msg: "bi2DataInteractions:startAVSverificationPlansListener: ",
      success: true,
    };

    let ids = self.IV_schema_ids;
    let call_result = self.listenerExistsCheck(self,
      [ids.fs1,ids.fs2,ids.fs138],
      [recycler_business_uid,recycler_location_uid, "fs138AVSverificationPlans"]
    )
    result.debug_data.push(call_result);

    if (call_result.exists === true) {
      result.return_msg += `Listener already started for fs1.../${recycler_business_uid}/fs2.../${recycler_location_uid}/fs138`;
      result.success = true;
      return result;
    }

    const query = collection(
      self.IV_firebase_app,
      "fs1RecyclerBusiness",
      recycler_business_uid,
      "fs2RecyclerLocation",
      recycler_location_uid,
      "fs138AVSverificationPlans",
    );

    const unsub = onSnapshot(query, (doc_snapshots) => {
      doc_snapshots.docChanges().forEach((change) => {
        const fs138_doc_id = change.doc.id;
        const doc_data = change.doc.data() as fs138AVSverificationPlans;
        self.__fs138AVSverificationPlansCallback(
          self,
          doc_data,
          fs138_doc_id
        );
      });
    });

    call_result = self.registerListener(self,
      [ids.fs1,ids.fs2,ids.fs138],
      [recycler_business_uid,recycler_location_uid,"collection"],
      unsub
    )
    result.debug_data.push(call_result);

    return result;
  }

  __fs138AVSverificationPlansCallback(
    class_instance: bi2DataInteractions,
    firestore_document: fs138AVSverificationPlans,
    fs138_doc_id: string
  ): void {
    const self = class_instance;
    const doc_data = firestore_document;

    if (!doc_data) { return; }

    Vue.set(
      self.IV_avs_verification_plans.value, 
      fs138_doc_id, {
        avs_verification_type: doc_data.avs_verification_type,
      }
    )
  }


  startImportedContactsHistoryListener(
    class_instance: bi2DataInteractions,
    recycler_business_uid: string,
    recycler_location_uid: string,
  ): Result {

    const self = class_instance;

    let result: Result = {
      debug_data: [],
      return_msg: "bi2DataInteractions:startImportedContactsHistoryListener: ",
      success: true,
    };

    let ids = self.IV_schema_ids;
    let call_result = self.listenerExistsCheck(self,
      [ids.fs1,ids.fs2,ids.fs133],
      [recycler_business_uid,recycler_location_uid,"collection"]
      )
    result.debug_data.push(call_result);

    if (call_result.exists === true) {
      result.return_msg += `Listener already started for fs1.../${recycler_business_uid}/fs2.../${recycler_location_uid}/fs133...`;
      result.success = true;
      return result;
    }

    const query = collection(
      self.IV_firebase_app,
      "fs1RecyclerBusiness",
      recycler_business_uid,
      "fs2RecyclerLocation",
      recycler_location_uid,
      "fs133ImportedContactsHistory",
    );

    // here we are listening on import records of a recycler location
    const unsub = onSnapshot(query, (doc_snapshots) => {
      doc_snapshots.docChanges().forEach((change) => {
        const doc_data = change.doc.data() as fs133ImportedContactsHistory;
        self.__fs133CollectionListenerCallback(
          self,
          doc_data,
        );
      });
    });

    call_result = self.registerListener(self,
      [ids.fs1,ids.fs2,ids.fs133],
      [recycler_business_uid,recycler_location_uid,"collection"],
      unsub
      )
    result.debug_data.push(call_result);

    return result;
  }

  __fs133CollectionListenerCallback(
    class_instance: bi2DataInteractions,
    firestore_document: fs133ImportedContactsHistory,
  ): void {
    const self = class_instance;
    const doc_data = firestore_document;

    if (!doc_data) { return; }

    Vue.set(
      self.IV_imported_contacts_history.value, 
      doc_data.imported_contacts_uid, [
        doc_data.imported_contacts_uid,
        doc_data.remote_blob_path,
        doc_data.consumer_phone_numbers,
        doc_data.date_uploaded,
        doc_data.file_name,
      ])

    self.IV_imported_contacts_history_last_changed.value =  ref(Date.now())
  }

  startTransactionTermsAndConditionsListener(
    class_instance: bi2DataInteractions,
    recycler_business_uid: string,
    recycler_location_uid: string,
  ): Result {

    const self = class_instance;

    let result: Result = {
      debug_data: [],
      return_msg: "bi2DataInteractions:startConversationQueueListener: ",
      success: true,
    };

    const query = collection(
      self.IV_firebase_app,
      `fs130RecyclerTermsAndConditions`
    );

    let ids = self.IV_schema_ids;
    let call_result = self.listenerExistsCheck(self,
      [ids.fs1,ids.fs2],
      [recycler_business_uid,recycler_location_uid,"collection"]
      )
    result.debug_data.push(call_result);
    if (call_result.exists === true) {
      result.return_msg += `Listener already started for fs130RecyclerTermsAndConditions`;
      result.success = true;
      return result;
    }

    // here we are listening on whole conversation queue for a recycler location
    const unsub = onSnapshot(query, (doc_snapshots) => {
      doc_snapshots.docChanges().forEach((change) => {
        if (change.type === "added" || change.type === "modified") {
          if (!change.doc.id.includes(`${recycler_business_uid}-${recycler_location_uid}`)) {return;}
          const doc_data = change.doc.data() as fs130RecyclerTermsAndConditions;
          self.__fs130CollectionListenerCallback(
            self,
            doc_data,
            recycler_business_uid,
            recycler_location_uid
          );
        }
      });
    });

    call_result = self.registerListener(self,
      [ids.fs1,ids.fs2],
      [recycler_business_uid,recycler_location_uid,"collection"],
      unsub
      )
    result.debug_data.push(call_result);

    return result;
  }

  __fs130CollectionListenerCallback(
    class_instance: bi2DataInteractions,
    firestore_document: fs130RecyclerTermsAndConditions,
    recycler_business_uid: string,
    recycler_location_uid: string
  ): void {
    const self = class_instance;
    const doc_data = firestore_document;

    if (!doc_data) { return; }

    let business_and_recycler_location_and_type_number_uuid = recycler_business_uid + "-" + recycler_location_uid + "-" + doc_data.type_number;

    Vue.set(self.IV_transaction_terms_and_conditions.value, business_and_recycler_location_and_type_number_uuid, doc_data)
  }

  startConsumerConversationHistoryIndexListener(
    class_instance: bi2DataInteractions,
    recycler_business_uid: string,
    recycler_location_uid: string,
    consumer_uid: string,
    load_sub_collection = true
  ): Result {
    const self = class_instance;

    let result: Result = {
      debug_data: [],
      return_msg:
        "bi2DataInteractions:startConsumerConversationHistoryIndexListener: ",
      success: true,
    };


    let ids = self.IV_schema_ids;
    let call_result = self.listenerExistsCheck(self,
      [ids.fs1,ids.fs2,ids.fs4,ids.fs6],
      [recycler_business_uid,recycler_location_uid,consumer_uid,"collection"]
      )
    result.debug_data.push(call_result);
    if (call_result.exists === true) {
      result.return_msg += `Listener already started for fs1RecyclerBusiness/${recycler_business_uid}/fs2RecyclerLocation/${recycler_location_uid}/fs4ConsumerContacts/${consumer_uid}/fs6ConsumerConversations`;
      result.success = false;
      return result;
    }

    const col_ref = collection(
      self.IV_firebase_app,
      `fs1RecyclerBusiness/${recycler_business_uid}/fs2RecyclerLocation/${recycler_location_uid}/fs4ConsumerContacts/${consumer_uid}/fs6ConsumerConversations`
    );

    // here we are listening on whole conversation queue for a fs6ConsumerConversations
    const unsub = onSnapshot(col_ref, (doc_snapshots) => {
      doc_snapshots.docChanges().forEach((change) => {
        if (change.type === "added" || change.type === "modified") {
          const doc_data = change.doc.data() as fs6ConsumerConversations;
          self.__fs6ListenerCallback(
            self,
            doc_data,
            recycler_business_uid,
            recycler_location_uid,
            consumer_uid,
            true,
            false,
            false,
            true,
            true
          );
        }
      });
    });

    call_result = self.registerListener(self,
      [ids.fs1,ids.fs2,ids.fs4,ids.fs6],
      [recycler_business_uid,recycler_location_uid,consumer_uid,"collection"],
      unsub
    )

    return result;
  };


  __fs6ListenerCallback(
    class_instance: bi2DataInteractions,
    firestore_document: fs6ConsumerConversations,
    recycler_business_uid: string,
    recycler_location_uid: string,
    consumer_uid: string,
    load_consumer_messages = false,
    load_feedback_log = false,
    load_employee_chat = false,
    load_payment_links = false,
    load_consumer_vehicle = false
  ): void {
    const self = class_instance;
    const doc_data = firestore_document;

    if (!doc_data) { return; }
    if (doc_data.conversation_uid === null || doc_data.conversation_uid == undefined) { return; }

    let consumer_and_conversation_uuid = consumer_uid + "-" + doc_data.conversation_uid;

    let consumer_name  = self.IV_consumer_contacts.value[consumer_uid][self.IV_al_consumer_contacts.first_name] + " " + self.IV_consumer_contacts.value[consumer_uid][self.IV_al_consumer_contacts.last_name];
    
    let phone_number = self.IV_consumer_contacts.value[consumer_uid][self.IV_al_consumer_contacts.primary_phone_number].phone_number;

    ///// make sure this conversation has atleast empty data structures for vue listeners
    if (consumer_and_conversation_uuid in self.IV_consumer_conversations.value === false) {
      Vue.set(self.IV_consumer_conversations.value, consumer_and_conversation_uuid, {});
    }

    if (consumer_and_conversation_uuid in self.IV_consumer_conversation_payment_links.value === false) {
      Vue.set(self.IV_consumer_conversation_payment_links.value, consumer_and_conversation_uuid, {});
    }

    let conversation_index_data:any = {
      "consumer_uid": consumer_uid,
      "conversation_uid":doc_data.conversation_uid,
      "consumer_name": consumer_name,
      "consumer_rating": self.IV_consumer_contacts.value[consumer_uid][self.IV_al_consumer_contacts.average_consumer_rating],
      "phone_number": phone_number,
      "assigned_employee": doc_data.assigned_employee_uid,
      "type": doc_data.conversation_type,
      "uuid": consumer_and_conversation_uuid,
      "status_codes": doc_data.conversation_status_codes,
      "conversation_invite_url": doc_data.conversation_invite_url || "",
      "last_message_timestamp": doc_data.last_message_timestamp || "",
      "last_activity_time": "",
      "activity_status": "",
      "consumer_typing_status": "",
      "entered_queue_timestamp": doc_data.entered_queue_timestamp
     }
  
    for (let entry_key in conversation_index_data) {
      Vue.set(
        self.IV_consumer_conversations.value[consumer_and_conversation_uuid],
        entry_key,
        conversation_index_data[entry_key]  
      );
    }    
    /////</end> make sure this conversation is in the overall list of conversations

    ///// create if needed and populate IV_consumer_contact_conversation_history_index entry
    if ( consumer_uid in self.IV_consumer_contact_conversation_history_index.value === false) {
      Vue.set(
        self.IV_consumer_contact_conversation_history_index.value,
        consumer_uid,
        {}
      );
    }

    Vue.set(
      self.IV_consumer_contact_conversation_history_index.value[consumer_uid],
      doc_data.conversation_uid,
       [
        doc_data.conversation_uid,
        consumer_uid,
        doc_data.chat_start_timestamp,
        doc_data.chat_finished_timestamp,
        doc_data.conversation_consumer_rating,
      ]
    );
    /////</end> create if needed and populate IV_consumer_contact_conversation_history_index entry

    // TODO ?
    // create IV_consumer_conversation_details or  if needed and populate / update IV_consumer_conversation_details
 
    ////// logic for if this conversation is in queue
    let active_conversation_flag = false;
    
    if (doc_data.conversation_status_codes.indexOf("a") !== -1) { 
      active_conversation_flag = true;
      
      //make sure a queue entry exists
      if (self.IV_conversation_queue_entries.value[consumer_and_conversation_uuid] === undefined) {
        Vue.set(
          self.IV_conversation_queue_entries.value,
          consumer_uid + "-" + doc_data.conversation_uid,
          {"uuid": consumer_and_conversation_uuid, consumer_uid: consumer_uid, conversation_uid: doc_data.conversation_uid}
        );      
      }

      //make sure an active conversation entry doesn't exist
      if (self.IV_employees_active_conversations.value[consumer_and_conversation_uuid] !== undefined) {
        Vue.delete(self.IV_employees_active_conversations.value,consumer_and_conversation_uuid);
      }
    }
    //////</end> logic for if this conversation is in queue

    ////// logic for if this conversation is an active conversation with the employee
    if (doc_data.conversation_status_codes.indexOf("b") !== -1) { 
      active_conversation_flag = true;
      
      //make sure an active entry exists
      let add_to_active_list_flag = false;
      if (consumer_and_conversation_uuid in self.IV_yard_side_chat_invites.value === true &&
        self.IV_yard_side_chat_invites.value[consumer_and_conversation_uuid].last_response === 2) {
          add_to_active_list_flag = true;
        }
      
      if (doc_data.assigned_employee_uid === self.IV_active_employee_info.employee_uid) {
        add_to_active_list_flag = true;
      }
      
      if ( add_to_active_list_flag === true && 
        self.IV_employees_active_conversations.value[consumer_and_conversation_uuid] === undefined) {
        Vue.set(
          self.IV_employees_active_conversations.value,
          consumer_uid + "-" + doc_data.conversation_uid,
          {"uuid": consumer_and_conversation_uuid, consumer_uid: consumer_uid, conversation_uid: doc_data.conversation_uid}
        );      
      }

      //make sure a queued conversation entry doesn't exist
      if (self.IV_conversation_queue_entries.value[consumer_and_conversation_uuid] !== undefined) {
        Vue.delete(self.IV_conversation_queue_entries.value,consumer_and_conversation_uuid);
      }
    }

    ////// logic for if this conversation is ended
    if (doc_data.conversation_status_codes.indexOf("z") !== -1) { 

      // make sure an active conversation entry doesn't exist
      if (consumer_and_conversation_uuid in self.IV_employees_active_conversations.value == true) {
        Vue.delete(self.IV_employees_active_conversations.value, consumer_and_conversation_uuid);
      }

      if (consumer_uid in self.IV_all_active_conversations.value &&
          self.IV_all_active_conversations.value[consumer_uid][doc_data.conversation_uid] !== undefined
      ) {
        Vue.delete(self.IV_all_active_conversations.value[consumer_uid], doc_data.conversation_uid);
        Vue.set(self.IV_consumer_conversation_counts.value,consumer_uid,
        self.IV_all_active_conversations.value[consumer_uid].length );
      }

      // make sure a queued conversation entry doesn't exist
      if (self.IV_conversation_queue_entries.value[consumer_and_conversation_uuid] !== undefined) {
        Vue.delete(self.IV_conversation_queue_entries.value,consumer_and_conversation_uuid);
      }
    }

    if (active_conversation_flag === true) { 
      if(consumer_uid in self.IV_all_active_conversations.value === false) {
        Vue.set(self.IV_all_active_conversations.value, consumer_uid, {});
      }

      if(doc_data.conversation_uid in self.IV_all_active_conversations.value[consumer_uid] === false) {    
      Vue.set(self.IV_all_active_conversations.value[consumer_uid],
        doc_data.conversation_uid,
        {
          conversation_uid: doc_data.conversation_uid,
          consumer_uid: consumer_uid
        });

      }
      Vue.set(self.IV_consumer_conversation_counts.value,consumer_uid,
        Object.keys(self.IV_all_active_conversations.value[consumer_uid]).length );
    }
    //////</end> logic for if this conversation is an active conversation with the employee

    let consumer_business_name = "";
    let vehicle_and_part_info = undefined;
    let vehicle_and_part_info_summary = undefined;
    // load existing data if it exists
    if(consumer_and_conversation_uuid in self.IV_consumer_conversation_details.value) {
      consumer_business_name = "" || self.IV_consumer_conversation_details.value[consumer_and_conversation_uuid][self.IV_al_consumer_conversation_details.consumer_business_name];
      vehicle_and_part_info = null || self.IV_consumer_conversation_details.value[consumer_and_conversation_uuid][self.IV_al_consumer_conversation_details.ma1_vehicles_and_parts_array];
      vehicle_and_part_info_summary = null || self.IV_consumer_conversation_details.value[consumer_and_conversation_uuid][self.IV_al_consumer_conversation_details.ma2_vehicle_part_summary_data]
    };

    Vue.set(
      self.IV_consumer_conversation_details.value,
      consumer_and_conversation_uuid,
      [
        consumer_uid,
        doc_data.conversation_uid,
        consumer_and_conversation_uuid, 
        doc_data.entered_queue_timestamp,
        consumer_name,
        "fs10_message_uid", // UID of the last received chat message fs10
        doc_data.conversation_status_codes.indexOf("a") ? true : false, //"fs6 > conversation_status_codes",
        consumer_business_name,
        phone_number,
        vehicle_and_part_info, // vehicle and interchange part number information
        vehicle_and_part_info_summary // vehicle and interchange part number information
      ]
    );

    if (load_payment_links === true) {
      self.startPaymentLinksListener(
        self, 
        recycler_business_uid,
        recycler_location_uid,
        consumer_uid,
        doc_data.conversation_uid
      );
    }

    if (load_payment_links === true) {
      self.startConsumerIdentityVerificationDetailsListener(
        self, 
        recycler_business_uid,
        recycler_location_uid,
        consumer_uid,
        doc_data.conversation_uid
      );
    }

    if (load_consumer_messages === true) {
      self.startConsumerConversationMessagesListener(self,
        recycler_business_uid,
        recycler_location_uid,
        consumer_uid,
        doc_data.conversation_uid
      );
    }

    if (load_consumer_vehicle === true ) {
      self.startConsumerConversationVehicleListener(self,
        recycler_business_uid,
        recycler_location_uid,
        consumer_uid,
        doc_data.conversation_uid
      )
    }
  }

  startConsumerFeedbackLogListener(
    class_instance: bi2DataInteractions,
    recycler_business_uid: string,
    recycler_location_uid: string,
    consumer_uid: string,

    load_sub_collection = true
  ): Result {
    const self = class_instance;
    
    let result: Result = {
      debug_data: [],
      return_msg: "bi2DataInteractions:startConsumerFeedbackLogListener: ",
      success: true,
    };

    let ids = self.IV_schema_ids;
    let call_result = self.listenerExistsCheck(self,
      [ids.fs1,ids.fs2,ids.fs4,ids.fs13],
      [recycler_business_uid,recycler_location_uid,consumer_uid,"collection"]
      )
    result.debug_data.push(call_result);
    if (call_result.exists === true) {
      result.return_msg += `Listener already started for fs1RecyclerBusiness/${recycler_business_uid}/fs2RecyclerLocation/${recycler_location_uid}/fs4ConsumerContacts/${consumer_uid}/fs13EmployeeFeedbackMessages`;
      result.success = true;
      return result;
    }
    //add placeholder value if it doesn't exist
    if ( consumer_uid in self.IV_consumer_feedback_logs.value === false) {
      Vue.set(self.IV_consumer_feedback_logs.value, consumer_uid, {});
    }

    const doc_ref = collection(
      self.IV_firebase_app,
      `fs1RecyclerBusiness/${recycler_business_uid}/fs2RecyclerLocation/${recycler_location_uid}/fs4ConsumerContacts/${consumer_uid}/fs13EmployeeFeedbackMessages`
    );

    //here we are listening on whole conversation queue for a fs13EmployeeFeedbackMessages
    const unsub = onSnapshot(doc_ref, (doc_snapshots) => {
      doc_snapshots.forEach((doc_snapshot) => {
        const doc_data = doc_snapshot.data() as fs13EmployeeFeedbackMessages;

        // Ignore the first entery without document uid.
        if (!doc_data.message_uid) { return; }
        
        self.__fs13CollectionListenerCallback(
          self,
          doc_data,
          recycler_business_uid,
          recycler_location_uid,
          consumer_uid
        );
      });
    });
  
    call_result = self.registerListener(self,
      [ids.fs1,ids.fs2,ids.fs4,ids.fs13],
      [recycler_business_uid,recycler_location_uid,consumer_uid,"collection"],
      unsub
      )
    result.debug_data.push(call_result);

    return result;
  }

  async __fs13CollectionListenerCallback(
    class_instance: bi2DataInteractions,
    firestore_document: fs13EmployeeFeedbackMessages,
    recycler_business_uid: string,
    recycler_location_uid: string,
    consumer_uid: string,
  ): Promise<void> {
    const self = class_instance;
    const doc_data = firestore_document;

    const arry_to_add = [
      doc_data.conversation_uid,
      doc_data.message_type,
      doc_data.message_content,
      doc_data.timestamp,
      doc_data.employee_uid,
    ];


    // checking for existing data in data collection.
    if (consumer_uid in self.IV_consumer_feedback_logs.value === false) {
      Vue.set(self.IV_consumer_feedback_logs.value,
        consumer_uid,
        {});
    }
    let message_uid = "typescript_fix";
    //message_uid will never be undefined, but we have to do this to prevent a typescript error because the document ID is optional when creating a new record.
    if (doc_data.message_uid !== undefined) {
      message_uid = doc_data.message_uid
    }
    Vue.set(self.IV_consumer_feedback_logs.value[consumer_uid],
      message_uid,
      arry_to_add);    

  }

  // fs43RecentQueueEntries
  startRecentQueueEntriesListener(
    class_instance: bi2DataInteractions,
    recycler_business_uid: string,
    recycler_location_uid: string
  ): Result {
    const self = class_instance;
    

    let result: Result = {
      debug_data: [],
      return_msg: "bi2DataInteractions:startRecentQueueEntriesListener: ",
      success: true,
    };

    // Check if already listener started for fs43RecentQueueEntries
    let ids = self.IV_schema_ids;
    let call_result = self.listenerExistsCheck(self,
      [ids.fs1,ids.fs2,ids.fs43],
      [recycler_business_uid,recycler_location_uid,"collection"]
      )
    result.debug_data.push(call_result);
    if (call_result.exists === true) {
      result.return_msg += `Listener already started for fs1RecyclerBusiness/${recycler_business_uid}/fs2RecyclerLocation/${recycler_location_uid}/fs43RecentQueueEntries/`;
      result.success = false;
      return result;
    }

    const doc_ref = collection(
      self.IV_firebase_app,
      `fs1RecyclerBusiness/${recycler_business_uid}/fs2RecyclerLocation/${recycler_location_uid}/fs43RecentQueueEntries`
    );

    //here we are listening on whole fs43RecentQueueEntries folder
    const unsub = onSnapshot(doc_ref, (doc_snapshots) => {
      doc_snapshots.forEach((doc_snapshot) => {
        const doc_data = doc_snapshot.data() as fs43RecentQueueEntries;
        self.__fs43RecentQueueEntriesListenerCallback(
          recycler_business_uid,
          recycler_location_uid,
          self,
          doc_data
        );
      });
    });


    call_result = self.registerListener(self,
      [ids.fs1,ids.fs2,ids.fs43],
      [recycler_business_uid,recycler_location_uid,"collection"],
      unsub
      )

    return result;
  }

  async __fs43RecentQueueEntriesListenerCallback(
    recycler_business_uid: string,
    recycler_location_uid: string,
    class_instance: bi2DataInteractions,
    firestore_document: fs43RecentQueueEntries
  ): Promise<void> {
    const self = class_instance;
    const doc_data = firestore_document;

    // Writing data to IV_recent_queue_entries
    Vue.set(
      self.IV_recent_queue_entries,
      firestore_document.consumer_contact_uid
       + "-" + firestore_document.conversation_uid,
      {"consumer_uid": firestore_document.consumer_contact_uid,
      "conversation_uid": firestore_document.conversation_uid
    }
    );
  }

  // fs45RecentRemovedQueueEntries
  startRecentRemovedQueueEntriesListener(
    class_instance: bi2DataInteractions,
    recycler_business_uid: string,
    recycler_location_uid: string
  ): Result {
    const self = class_instance;
    

    let result: Result = {
      debug_data: [],
      return_msg:
        "bi2DataInteractions:startRecentRemovedQueueEntriesListener: ",
      success: true,
    };

    // Check if already listener started for fs45RecentRemovedQueueEntries
    let ids = self.IV_schema_ids;
    let call_result = self.listenerExistsCheck(self,
      [ids.fs1,ids.fs2,ids.fs45],
      [recycler_business_uid,recycler_location_uid,"collection"]
      )
    result.debug_data.push(call_result);
    if (call_result.exists === true) {
      result.return_msg += `Listener already started for fs1RecyclerBusiness/${recycler_business_uid}/fs2RecyclerLocation/${recycler_location_uid}/fs45RecentRemovedQueueEntries/`;
      result.success = false;
      return result;
    }

    const doc_ref = collection(
      self.IV_firebase_app,
      `fs1RecyclerBusiness/${recycler_business_uid}/fs2RecyclerLocation/${recycler_location_uid}/fs45RecentRemovedQueueEntries`
    );

    //here we are listening on whole fs45RecentRemovedQueueEntries folder
    const unsub = onSnapshot(doc_ref, (doc_snapshots) => {
      doc_snapshots.forEach((doc_snapshot) => {
        const doc_data = doc_snapshot.data() as fs45RecentRemovedQueueEntries;
        self.__fs45RecentRemovedQueueEntriesListenerCallback(
          recycler_business_uid,
          recycler_location_uid,
          self,
          doc_data
        );
      });
    });


    call_result = self.registerListener(self,
      [ids.fs1,ids.fs2,ids.fs45],
      [recycler_business_uid,recycler_location_uid,"collection"],
      unsub
      )
    result.debug_data.push(call_result);

    return result;
  }

  async __fs45RecentRemovedQueueEntriesListenerCallback(
    recycler_business_uid: string,
    recycler_location_uid: string,
    class_instance: bi2DataInteractions,
    firestore_document: fs45RecentRemovedQueueEntries
  ): Promise<void> {
    const self = class_instance;
    const doc_data = firestore_document;

    // Writing data to IV_recent_removed_queued_entries
    Vue.set(
      self.IV_recent_removed_queued_entries,
      firestore_document.conversation_uid,
      firestore_document
    );
  }

  startActiveConsumerConversationJoinsListener(
    class_instance: bi2DataInteractions,
    recycler_business_uid: string,
    recycler_location_uid: string,
  ): Result {
    const self = class_instance;
    
    let result: Result = {
      debug_data: [],
      return_msg:
        "bi2DataInteractions:startActiveConsumerConversationJoinsListener: ",
      success: true,
    };

    // Check if already listener started for fs46EmployeeActiveConsumerConversationJoins
    let ids = self.IV_schema_ids;
    let call_result = self.listenerExistsCheck(self,
      [ids.fs1,ids.fs2,ids.fs46],
      [recycler_business_uid,recycler_location_uid,"collection"]
      )
    result.debug_data.push(call_result);
    if (call_result.exists === true) {
      result.return_msg += `Listener already started for fs1RecyclerBusiness/${recycler_business_uid}/fs2RecyclerLocation/${recycler_location_uid}/fs46EmployeeActiveConsumerConversationJoins/`;
      result.success = true;
      return result;
    }

    const collection_ref = collection(
      self.IV_firebase_app,
      "fs1RecyclerBusiness",
      recycler_business_uid,
      "fs2RecyclerLocation",
      recycler_location_uid,
      "fs46EmployeeActiveConsumerConversationJoins",
    );
    const unsub = onSnapshot(collection_ref, (doc_snapshots) => {
      doc_snapshots.forEach((doc_snapshot) => {
      const doc_data = doc_snapshot.data() as fs46EmployeeActiveConsumerConversationJoins;
        self.__fs46EmployeeActiveConsumerConversationJoinsListenerCallback(
          recycler_business_uid,
          recycler_location_uid,
          self,
          doc_data
        );
      });
    });

    call_result = self.registerListener(self,
      [ids.fs1,ids.fs2,ids.fs46],
      [recycler_business_uid,recycler_location_uid,"collection"],
      unsub
      )
    result.debug_data.push(call_result);

    return result;
  }

  //TODO determine if we still need fs46 its actually not getting written to all the time and things are working because of fs27
  async __fs46EmployeeActiveConsumerConversationJoinsListenerCallback(
    recycler_business_uid: string,
    recycler_location_uid: string,
    class_instance: bi2DataInteractions,
    firestore_document: fs46EmployeeActiveConsumerConversationJoins
  ): Promise<void> {
    const self = class_instance;
    const doc_data = firestore_document;
    for (let entry_key in firestore_document) {
      if (entry_key.indexOf("ac_") === 0) {
        
        let uid_strings = entry_key.replace("ac_","");
        let [consumer_uid,conversation_uid] = uid_strings.split("_");

        self.startConsumerContactListener(self,
          recycler_business_uid,
          recycler_location_uid,
          consumer_uid,
          false,
          conversation_uid
        );
      }
    }
  }

  startConsumerConversationVehicleListener(
    class_instance: bi2DataInteractions,
    recycler_business_uid: string,
    recycler_location_uid: string,
    consumer_uid: string,
    conversation_uid: string
  ): Result {
    const self = class_instance;
    let result: Result = {
      debug_data: [],
      return_msg: "bi2DataInteractions:startConsumerConversationVehicleListener: ",
      success: true,
    };
    
    const ids = self.IV_schema_ids
    let call_result = self.listenerExistsCheck(self,
      [ids.fs1,ids.fs2,ids.fs4,ids.fs6,ids.fs35],
      [recycler_business_uid,recycler_location_uid,consumer_uid,conversation_uid,"collection"]
    )

    result.debug_data.push(call_result);

    if (call_result.exists === true) {  
      result.return_msg += `Listener already started for fs1RecyclerBusiness/${recycler_business_uid}/fs2RecyclerLocation/${recycler_location_uid}/fs4ConsumerContacts/${consumer_uid}/fs6ConsumerConversations/${conversation_uid}/fs35ConversationVehicle`;
      result.success = true;
      return result;
    }

    const doc_ref = collection(
      self.IV_firebase_app,
      `fs1RecyclerBusiness/${recycler_business_uid}/fs2RecyclerLocation/${recycler_location_uid}/fs4ConsumerContacts/${consumer_uid}/fs6ConsumerConversations/${conversation_uid}/fs35ConversationVehicle`
    );

    // here we are listening on whole conversation queue for a fs6ConsumerConversations
    const unsub = onSnapshot(doc_ref, (doc_snapshots) => {
      doc_snapshots.docChanges().forEach((change) => {
        if (change.type === "added") {
          let fs35_doc_id = change.doc.id;
          const doc_data = change.doc.data() as fs35ConversationVehicle;
          self.__fs35CollectionListenerCallback(
            self,
            doc_data,
            recycler_business_uid,
            recycler_location_uid,
            consumer_uid,
            conversation_uid,
            fs35_doc_id,
          );
        }
      });
    });

    call_result = self.registerListener(self,
      [ids.fs1,ids.fs2,ids.fs4,ids.fs6,ids.fs10],
      [recycler_business_uid,recycler_location_uid,consumer_uid,conversation_uid,"collection"],
      unsub
      )

    return result;
  }

  async __fs35CollectionListenerCallback(
    class_instance: bi2DataInteractions,
    firestore_document: fs35ConversationVehicle,
    recycler_business_uid: string,
    recycler_location_uid: string,
    consumer_uid: string,
    conversation_uid: string,
    fs35_doc_id: string,
    load_sub_collection = true
  ): Promise<void> {
    const self = class_instance;
    const doc_data = firestore_document;
    const consumer_and_conversation_uuid = consumer_uid + "-" + conversation_uid;
    let summary_string = "";

    let entry_type = 1;
    if(doc_data.interchange_part_number) {
      entry_type = 2;
    }
    let data_to_add = [];
    data_to_add.push(entry_type);
    if(entry_type === 1) {
      data_to_add.push(
        undefined,
        doc_data.vehicle_make_uid,
        doc_data.vehicle_model_uid,
        doc_data.vehicle_year
        );
        summary_string = doc_data.vehicle_year + " " + doc_data.vehicle_make_uid + " " + doc_data.vehicle_model_uid;
      if(doc_data.vehicle_parts_description) {
        data_to_add.push(doc_data.vehicle_parts_description);
        summary_string += " - " + doc_data.vehicle_parts_description.slice(0,40); 
      }
    } else if (entry_type === 2 ) {
      data_to_add.push(doc_data.interchange_part_number);
      //@ts-ignore
      summary_string = "Part Number: " + doc_data.interchange_part_number.slice(0,80);
    }

    // if there hasn't be a value set before create an empty dictionary
    if(self.IV_consumer_conversation_details.value[consumer_and_conversation_uuid][self.IV_al_consumer_conversation_details.ma1_vehicles_and_parts_array] === undefined) {
      self.IV_consumer_conversation_details.value[consumer_and_conversation_uuid][self.IV_al_consumer_conversation_details.ma1_vehicles_and_parts_array] = {};        
    }

    Vue.set(self.IV_consumer_conversation_details.value[consumer_and_conversation_uuid][self.IV_al_consumer_conversation_details.ma1_vehicles_and_parts_array],
      fs35_doc_id,
      data_to_add
    );

    //// update the summary data the UI shows for the vehicles and parts

    let vehicle_count =0;
    let part_number_count = 0;
    //get the counts for vehicles and parts
    for(let entry_key in self.IV_consumer_conversation_details.value[consumer_and_conversation_uuid][self.IV_al_consumer_conversation_details.ma1_vehicles_and_parts_array] ) {
      //@ts-ignore
      if(self.IV_consumer_conversation_details.value[consumer_and_conversation_uuid][self.IV_al_consumer_conversation_details.ma1_vehicles_and_parts_array][entry_key][self.IV_al_consumer_conversation_details.ma1i_type] === 1) {
        vehicle_count+=1;
      } else { part_number_count += 1;}      
    }

    //update the data in the structure vue.js will use
    Vue.set(self.IV_consumer_conversation_details.value[consumer_and_conversation_uuid],
      self.IV_al_consumer_conversation_details.ma2_vehicle_part_summary_data,
      [vehicle_count + part_number_count, vehicle_count, part_number_count,summary_string]
    );
    
    ////</end> update the summary data the UI shows for the vehicles and parts


  }

  startConsumerConversationMessagesListener(
    class_instance: bi2DataInteractions,
    recycler_business_uid: string,
    recycler_location_uid: string,
    consumer_uid: string,
    conversation_uid: string
  ): Result {
    const self = class_instance;
    let result: Result = {
      debug_data: [],
      return_msg: "bi2DataInteractions:startConsumerConversationMessagesListener: ",
      success: true,
    };
    
    const ids = self.IV_schema_ids
    let call_result = self.listenerExistsCheck(self,
      [ids.fs1,ids.fs2,ids.fs4,ids.fs6,ids.fs10],
      [recycler_business_uid,recycler_location_uid,consumer_uid,conversation_uid,"collection"]
      )
    result.debug_data.push(call_result);
    if (call_result.exists === true) {  
      result.return_msg += `Listener already started for fs1RecyclerBusiness/${recycler_business_uid}/fs2RecyclerLocation/${recycler_location_uid}/fs4ConsumerContacts/${consumer_uid}/fs6ConsumerConversations/${conversation_uid}/fs10MessagesWithConsumer`;
      result.success = true;
      return result;
    }

    ////ensure vue has atleast empty data structures for reactivity
    let consumer_and_conversation_uuid = consumer_uid + "-" + conversation_uid;
    if (consumer_and_conversation_uuid in self.IV_consumer_conversation_details.value === false ){
    Vue.set(self.IV_consumer_conversation_details.value, consumer_and_conversation_uuid, {});
    }
    if (consumer_and_conversation_uuid in self.IV_consumer_conversation_messages.value === false ){
      Vue.set(self.IV_consumer_conversation_messages.value, consumer_and_conversation_uuid, []);
    }
    ////</end> ensure vue has atleast empty data structures for reactivity


    const doc_ref = collection(
      self.IV_firebase_app,
      `fs1RecyclerBusiness/${recycler_business_uid}/fs2RecyclerLocation/${recycler_location_uid}/fs4ConsumerContacts/${consumer_uid}/fs6ConsumerConversations/${conversation_uid}/fs10MessagesWithConsumer`
    );

    // here we are listening on whole conversation queue for a fs6ConsumerConversations
    const unsub = onSnapshot(doc_ref, (doc_snapshots) => {
      doc_snapshots.docChanges().forEach((change) => {
        if (change.type === "added") {
          const doc_data = change.doc.data() as fs10MessagesWithConsumer;
          self.__fs10CollectionListenerCallback(
            self,
            doc_data,
            recycler_business_uid,
            recycler_location_uid,
            consumer_uid,
            conversation_uid
          );
        }
      });
    });

    call_result = self.registerListener(self,
      [ids.fs1,ids.fs2,ids.fs4,ids.fs6,ids.fs10],
      [recycler_business_uid,recycler_location_uid,consumer_uid,conversation_uid,"collection"],
      unsub
      )

    return result;
  }

  async __fs10CollectionListenerCallback(
    class_instance: bi2DataInteractions,
    firestore_document: fs10MessagesWithConsumer,
    recycler_business_uid: string,
    recycler_location_uid: string,
    consumer_uid: string,
    conversation_uid: string,
    load_sub_collection = true
  ): Promise<void> {
    const self = class_instance;
    const doc_data = firestore_document;
    const consumer_and_conversation_uuid = consumer_uid + "-" + conversation_uid
    if (typeof(doc_data.content) == "string"
     && doc_data.content.indexOf("[FORCE_SENDER_TYPE_4]") === 0) {
      doc_data.sender = 4;
      doc_data.content = doc_data.content.replace("[FORCE_SENDER_TYPE_4]","");
    }
    
    let data_to_add =  [
      doc_data.sender,
      doc_data.type,
      doc_data.consumer_message_uid,
      doc_data.employee_uid,
      doc_data.timestamp,
    ];


    if (doc_data.type === 1) {

      if (doc_data.sender === 6) {
        return;
      }

      if (doc_data.content) {
        // Regex to match all URLs
        const urlRegex = /(https:\/\/\S+)/g;
        let formattedContent = doc_data.content;
    
        // Find all matches
        const matches = doc_data.content.match(urlRegex);
        if (matches) {
          matches.forEach(url => {
            let base_url = url;
            let text_after_url = "";
    
            // Check if there's text after the URL
            const urlIndex = doc_data.content.indexOf(url);
            const remainingContent = doc_data.content.substring(urlIndex + url.length);
            if (remainingContent && remainingContent[0] !== ' ') {
              const splitIndex = remainingContent.indexOf(' ');
              if (splitIndex !== -1) {
                base_url = url;
                text_after_url = remainingContent.substring(0, splitIndex);
              } else {
                base_url = url;
              }
            }
    
            // Format the URL
            let formatted_url = `<a class='text-blue-700' style='text-decoration: none;' onmouseover='this.style.textDecoration="underline";' onmouseout='this.style.textDecoration="none";' href='${base_url}' target='_blank'>${base_url}</a>`;
    
            // URLs from synergy platform should be on their own line
            if (url.includes("c.synergy-auto-solutions.com") && doc_data.sender > 2) {
              formatted_url = `<br>${formatted_url}<br>`;
            }
    
            // Add the text after the URL if present
            if (text_after_url) {
              formatted_url = `${formatted_url} ${text_after_url}`;
            }
    
            // Replace the URL in the content with the formatted URL
            formattedContent = formattedContent.replace(url, formatted_url);
          });
    
          // Update the content
          doc_data.content = formattedContent;
        }
      }

      data_to_add.push(doc_data.content);
      
    }
    else if (doc_data.type === 2 || doc_data.type === 3){
        data_to_add.push(doc_data.uploaded_file_link);
        data_to_add.push(doc_data.uploaded_file_name);
        //@ts-ignore TS can't tell this is the 3rd index being appended so it thinks this is pushing to the file_link index
        data_to_add.push(doc_data.uploaded_file_size);
        if(doc_data.uploaded_file_name === undefined) {
          doc_data.uploaded_file_name = "Uploaded File";
        }

        //@ts-ignore this won't be undefiend because it only runs on messages with files
        if(doc_data.uploaded_file_name.match(/\.(jpg|jpeg|png|gif)$/i)) {
          data_to_add.push(1); // set image file type flag
        //@ts-ignore this won't be undefiend because it only runs on messages with files
        } else  if(doc_data.uploaded_file_name.match(/\.(mpg|mpeg|mp4|mov)$/i)) {
          data_to_add.push(2); // set video file type flag
        } else {
          data_to_add.push(3);
        }        
    }

    if(doc_data.type === 1 && doc_data.sender === 1) {
      if((self.IV_consumer_conversation_last_consumer_message.value[consumer_and_conversation_uuid] === undefined )
         || (doc_data.timestamp > self.IV_consumer_conversation_last_consumer_message.value[consumer_and_conversation_uuid][4])) {
        Vue.set(self.IV_consumer_conversation_last_consumer_message.value,
          consumer_and_conversation_uuid,data_to_add);
      }
    }


    let existing_messages =
      self.IV_consumer_conversation_messages.value[consumer_and_conversation_uuid];

    if (!existing_messages) {
      existing_messages = [];
    }
    //@ts-ignore
    existing_messages.push(data_to_add);
    
    Vue.set(self.IV_consumer_conversation_details.value[consumer_and_conversation_uuid],
      self.IV_al_consumer_conversation_details.message_uid,
      doc_data.consumer_message_uid
      );

    Vue.set(
      self.IV_consumer_conversation_messages.value,
      consumer_and_conversation_uuid,
      existing_messages
    );
  }

  
  startPaymentLinksListener(
    class_instance: bi2DataInteractions,
    recycler_business_uid: string,
    recycler_location_uid: string,
    consumer_uid: string,
    conversation_uid: string,
    load_sub_collection = true
  ): Result {
    const self = class_instance;
    
    let result: Result = {
      debug_data: [],
      return_msg: "bi2DataInteractions:startPaymentLinksListener: ",
      success: true,
    };

    let ids = self.IV_schema_ids;
    let call_result = self.listenerExistsCheck(self,
      [ids.fs1,ids.fs2,ids.fs4,ids.fs6,ids.fs36],
      [recycler_business_uid,recycler_location_uid,consumer_uid,conversation_uid,"collection"]
      )
    result.debug_data.push(call_result);
    if (call_result.exists === true) {
      result.return_msg += `Listener already started for fs1RecyclerBusiness/${recycler_business_uid}/fs2RecyclerLocation/${recycler_location_uid}/fs4ConsumerContacts/${consumer_uid}/fs6ConsumerConversations/${conversation_uid}/fs36PaymentLinks`;
      result.success = true;
      return result;
    }

    const doc_ref = collection(
      self.IV_firebase_app,
      `fs1RecyclerBusiness/${recycler_business_uid}/fs2RecyclerLocation/${recycler_location_uid}/fs4ConsumerContacts/${consumer_uid}/fs6ConsumerConversations/${conversation_uid}/fs36PaymentLinks`
    );

    const unsub = onSnapshot(doc_ref, (doc_snapshots) => {
      doc_snapshots.docChanges().forEach((change) => {
        if (change.type === "added" || change.type === "modified" ) {
          const doc_data = change.doc.data() as fs36PaymentLinks;
          self.__fs36PaymentLinksCollectionListner(
            self,
            doc_data,
            recycler_business_uid,
            recycler_location_uid,
            consumer_uid,
            conversation_uid
          );
        }

        // Remove from IV
        if (change.type === "removed") {
          const consumer_and_conversation_uuid = consumer_uid + "-" + conversation_uid;
          const doc_data = change.doc.data() as fs36PaymentLinks;

          if (doc_data.payment_link_uid && doc_data.payment_link_uid in self.IV_consumer_conversation_payment_links.value[consumer_and_conversation_uuid] === true) {
            Vue.delete(
              self.IV_consumer_conversation_payment_links.value[consumer_and_conversation_uuid],
              doc_data.payment_link_uid
            );
          }
        }
      });
    });
    call_result = self.registerListener(self,
      [ids.fs1,ids.fs2,ids.fs4,ids.fs6,ids.fs36],
      [recycler_business_uid,recycler_location_uid,consumer_uid,conversation_uid,"collection"],
      unsub
      )
    result.debug_data.push(call_result);

    return result;
  }

  
  async __fs36PaymentLinksCollectionListner(
    class_instance: bi2DataInteractions,
    firestore_document: fs36PaymentLinks,
    recycler_business_uid: string,
    recycler_location_uid: string,
    consumer_uid: string,
    conversation_uid: string,
  ): Promise<void> {
    const self = class_instance;
    const doc_data = firestore_document;
    const consumer_and_conversation_uuid = consumer_uid + "-" + conversation_uid
  
    let data:any = {
      consumer_uid: consumer_uid,
      conversation_uid: conversation_uid,
      order_number: doc_data.order_number || "",
      url: doc_data.payment_link_url || "",
      payment_link_uid: doc_data.payment_link_uid,
      description: doc_data.payment_link_description || "", 
      amount: doc_data.amount || 0,
      adjusted_amount: doc_data.adjusted_amount || 0,
      payment_status: doc_data.payment_status || "no_status",
      terms_type_number: doc_data.terms_type_number || 0,
      payment_paid_time: doc_data.payment_paid_time || "",
      creation_date: doc_data.creation_date || "",
      last_status_check_time: doc_data.last_status_check_time || "",
      pickup_order: doc_data.pickup_order || "",
      recent_transaction_details: doc_data.recent_transaction_details || {"last4_digits": "Unknown", "cardholder_name": "Unknown", "card_type": "Unknown", "avs_status_code": "?", "avs_status_description": "Unknown", "cvv_status_code": "?", "cvv_status_description": "Unknown"},
      transaction_id: doc_data.transaction_id || "",
      refunded_amount: doc_data.refunded_amount || 0,
      refund_amount: doc_data.refund_amount || 0,
    }

    // have to trick typescript since payment_link_uid is set as string | undefined in the firestore schema due to the fact that the UID isn't known until we write the record. without the 2 lines below it gives an object is possibly undefined error.
    let payment_link_uid:any = "";
    payment_link_uid = doc_data.payment_link_uid;
    
    if (payment_link_uid in self.IV_consumer_conversation_payment_links.value[consumer_and_conversation_uuid] === false) {
      Vue.set(
        self.IV_consumer_conversation_payment_links.value[consumer_and_conversation_uuid],
        payment_link_uid, 
        {}
      );
    }
    
    for (let entry_key in data) {
      Vue.set(
        self.IV_consumer_conversation_payment_links.value[consumer_and_conversation_uuid][payment_link_uid],
        entry_key,
        data[entry_key]
      );
    }
  }

  startConsumerIdentityVerificationDetailsListener(
    class_instance: bi2DataInteractions,
    recycler_business_uid: string,
    recycler_location_uid: string,
    consumer_uid: string,
    conversation_uid: string
  ): Result {
    const self = class_instance;
    
    let result: Result = {
      debug_data: [],
      return_msg: "bi2DataInteractions:startConsumerIdentityVerificationDetailsListener: ",
      success: true,
    };

    let ids = self.IV_schema_ids;
    let call_result = self.listenerExistsCheck(self,
      [ids.fs1,ids.fs2,ids.fs4,ids.fs6,ids.fs107],
      [recycler_business_uid,recycler_location_uid,consumer_uid,conversation_uid,"collection"]
      )
    result.debug_data.push(call_result);
    if (call_result.exists === true) {
      result.return_msg += `Listener already started for fs1RecyclerBusiness/${recycler_business_uid}/fs2RecyclerLocation/${recycler_location_uid}/fs4ConsumerContacts/${consumer_uid}/fs6ConsumerConversations/${conversation_uid}/fs107StripeIdentityVerificationDetails`;
      result.success = true;
      return result;
    }

    const doc_ref = collection(
      self.IV_firebase_app,
      `fs1RecyclerBusiness/${recycler_business_uid}/fs2RecyclerLocation/${recycler_location_uid}/fs4ConsumerContacts/${consumer_uid}/fs6ConsumerConversations/${conversation_uid}/fs107StripeIdentityVerificationDetails`
    );

    const unsub = onSnapshot(doc_ref, (doc_snapshots) => {
      doc_snapshots.docChanges().forEach((change) => {
        if (change.type === "added" || change.type === "modified" ) {
          const doc_data = change.doc.data() as fs107StripeIdentityVerificationDetails;
          self.__fs107StripeIdentityVerificationDetailsCollectionListner(
            self,
            doc_data,
            recycler_business_uid,
            recycler_location_uid,
            consumer_uid,
            conversation_uid,
            change.doc.id
          );
        }
      });
    });
    call_result = self.registerListener(self,
      [ids.fs1,ids.fs2,ids.fs4,ids.fs6,ids.fs107],
      [recycler_business_uid,recycler_location_uid,consumer_uid,conversation_uid,"collection"],
      unsub
      )
    result.debug_data.push(call_result);

    return result;
  }

  async __fs107StripeIdentityVerificationDetailsCollectionListner(
    class_instance: bi2DataInteractions,
    firestore_document: fs107StripeIdentityVerificationDetails,
    recycler_business_uid: string,
    recycler_location_uid: string,
    consumer_uid: string,
    conversation_uid: string,
    fs107_uid: string
  ): Promise<void> {
    const self = class_instance;
    const doc_data = firestore_document;
    
    let data:any = {
      verification_session_id: doc_data.verification_session_id,
      verification_link: doc_data.verification_link,
      redirect_link: doc_data.redirect_link,
      status: doc_data.status, 
      time_verified: doc_data.time_verified,
      last_time_event_received: doc_data.last_time_event_received
    }

    let fs107_uid_str:any = "";
    fs107_uid_str = fs107_uid;
    
    
    Vue.set(
      self.IV_consumer_identity_verification_links.value,
      fs107_uid_str, 
      data
    );

    return;
  }

  startEmployeeAccountRecyclerJoinsListener(
    class_instance: bi2DataInteractions,
    firebase_account_uid: string,
    load_sub_collection = true
  ): Result {
    const self = class_instance;
    
    let result: Result = {
      debug_data: [],
      return_msg:
        "bi2DataInteractions:startEmployeeAccountRecyclerJoinsListener: ",
      success: true,
    };

    let ids = self.IV_schema_ids;
    let call_result = self.listenerExistsCheck(self,
      [ids.fs29,ids.fs30],
      [firebase_account_uid,"collection"]
      )
    result.debug_data.push(call_result);
    if (call_result.exists === true) {
      result.return_msg += `Listener already started for fs29.../${firebase_account_uid}/fs30`;
      result.success = false;
      return result;
    }

    const query = collection(
      self.IV_firebase_app,
      `fs29FirebaseAccounts/${firebase_account_uid}/fs30EmployeeAccountRecyclerJoins`
    );
    const unsub = onSnapshot(query, (doc_snapshots) => {
      doc_snapshots.forEach((doc_snapshot) => {
        const doc_data = doc_snapshot.data() as fs30EmployeeAccountRecyclerJoins;
        self.__fs30CollectionListenerCallback(
          self,
          doc_data,
          firebase_account_uid,
          load_sub_collection
        );
      });
    });

    call_result = self.registerListener(self,
      [ids.fs29,ids.fs30],
      [firebase_account_uid,"collection"],
      unsub
      )

    return result;
  }

  __fs30CollectionListenerCallback(
    class_instance: bi2DataInteractions,
    firestore_document: fs30EmployeeAccountRecyclerJoins,
    firebase_account_uid: string,
    load_sub_collection = true
  ): void {
    const self = class_instance;
    const doc = firestore_document;
    if (doc.employee_uid.indexOf("-disable") !== -1) { return;}

    Vue.set(self.IV_employee_account_recycler_joins.value, doc.employee_uid, {
      // from: fs30
      business_uid: doc.business_uid,
      employee_uid: doc.employee_uid,
      recycler_location_uid: doc.recycler_location_uid,
    });
    
    // don't load a second business
    if (self.IV_active_employee_info.employee_uid !== "") {
      load_sub_collection = false;
    }

    if (doc.business_uid in self.IV_business_recycler_joins.value === false) {
      Vue.set(self.IV_business_recycler_joins.value, doc.business_uid, {});
    }
    
    Vue.set(self.IV_business_recycler_joins.value[doc.business_uid], doc.recycler_location_uid, doc.recycler_location_uid);

    
    if (self.IV_active_employee_info.employee_uid === "") {
      self.IV_active_employee_info.recycler_location_uid = doc.recycler_location_uid;
      self.IV_active_employee_info.business_uid = doc.business_uid;
      self.IV_active_employee_info.employee_uid = doc.employee_uid;

      if (self.IV_loggedin_user_business_ids.value.includes(doc.business_uid)) {
        self.IV_active_employee_info.is_business_owner = true;
      }

      // 
      if (doc.employee_uid in self.IV_recycler_employees.value) {

        //@ts-ignore: typescript doesn't handle dynamic nested lookup dictionaries well
        const permissions = self.IV_recycler_employees[doc.employee_uid].permissions;

        self.IV_active_employee_info.permissions = {
          admin: permissions.admin || false,
          sales_person: permissions.sales_person || false,
          manager: permissions.manager || false,
          report_viewer: permissions.report_viewer || false
        }
      }
    }

    // Fetch business and locations for admins/managers of business
    let is_admin_employee = false;
    if (self.IV_active_employee_info.permissions && (self.IV_active_employee_info.permissions.admin == true || self.IV_active_employee_info.permissions.manager == true)) {
      is_admin_employee = true;
    }

    if (load_sub_collection || is_admin_employee) {
      this.startRecyclerBusinessListener(
        self,
        doc.business_uid,
        doc.recycler_location_uid
      );

      this.startRecyclerLocationsLogoListener(
        self,
        doc.business_uid,
        doc.recycler_location_uid
      );
    }
  }

  startRecyclerLocationsLogoListener(
    class_instance: bi2DataInteractions,
    recycler_business_uid: string,
    recycler_location_uid: string,
  ): Result {
    const self = class_instance;

    let result: Result = {
      debug_data: [],
      return_msg:
        "bi2DataInteractions:startRecyclerLocationsLogoListener: ",
      success: true,
    };

    let listener_uuid = recycler_business_uid + "-" + recycler_location_uid;
    const doc_ref = doc(
      self.IV_firebase_app,
      `fs85RecyclerLocationLogos/${listener_uuid}`
    );

    // TODO - check if listener already started
    const unsub = onSnapshot(doc_ref, (doc_snapshot) => {
      const doc_data = doc_snapshot.data() as fs85RecyclerLocationLogos;
      self.__fs85RecyclerLocationLogosCallback(
        self,
        doc_data,
        listener_uuid
      );
    });

    return result;
  }

  __fs85RecyclerLocationLogosCallback(
    class_instance: bi2DataInteractions,
    firestore_document: fs85RecyclerLocationLogos,
    recycler_business_location_uid: string,
    load_sub_collection = true
  ): void {
    const self = class_instance;
    const doc = firestore_document;

    if (!doc) { return; }

    Vue.set(self.IV_recyclers_logo_urls, recycler_business_location_uid, doc.url)
    
    return;
  }

  startYardOnlyChatListener(
    class_instance: bi2DataInteractions,
    recycler_business_uid: string,
    recycler_location_uid: string,
    consumer_uid: string,
    conversation_uid: string,
  ): Result {
    const self = class_instance;
    const listeners = self.IV_collection_active_listeners;
    let result: Result = {
      debug_data: [],
      return_msg:
        "bi2DataInteractions:startYardOnlyChatListener: ",
      success: true,
    };

    let listener_uuid = consumer_uid + "-" + conversation_uid

    if (listener_uuid in self.IV_employee_conversations.value === false) {
      Vue.set(self.IV_employee_conversations.value,
        listener_uuid,
        []);
    }

      const ids = self.IV_schema_ids
      let call_result = self.listenerExistsCheck(self,
      [ids.fs1,ids.fs2,ids.fs4,ids.fs6,ids.fs34],
      [recycler_business_uid,recycler_location_uid,consumer_uid,conversation_uid,"collection"]
      )
    result.debug_data.push(call_result);
    if (call_result.exists === true) {  
      result.return_msg += `fs1RecyclerBusiness/${recycler_business_uid}/fs2RecyclerLocation/${recycler_location_uid}/fs4ConsumerContacts/${consumer_uid}/fs6ConsumerConversations/${conversation_uid}/fs34RecyclerStaffOnlyMessages`;
      result.success = true;
      return result;
    }

    const doc_ref = collection(
      self.IV_firebase_app,
      `fs1RecyclerBusiness/${recycler_business_uid}/fs2RecyclerLocation/${recycler_location_uid}/fs4ConsumerContacts/${consumer_uid}/fs6ConsumerConversations/${conversation_uid}/fs34RecyclerStaffOnlyMessages`
    );

    // here we are listening on whole conversation queue for a fs6ConsumerConversations
    const unsub = onSnapshot(doc_ref, (doc_snapshots) => {
      doc_snapshots.docChanges().forEach((change) => {
        if ((change.type === "added") || (change.type === "modified")) {
          const doc_data = change.doc.data() as fs34RecyclerStaffOnlyMessages;

          if (doc_data["recycler_only_message_uid"]) {
            self.__fs34CollectionListenerCallback(
              self,
              doc_data,
              recycler_business_uid,
              recycler_location_uid,
              consumer_uid,
              conversation_uid
            );
          }
        }
      });
    });
    call_result = self.registerListener(self,
      [ids.fs1,ids.fs2,ids.fs4,ids.fs6,ids.fs34],
      [recycler_business_uid,recycler_location_uid,consumer_uid,conversation_uid,"collection"],
      unsub
      )
    result.debug_data.push(call_result);

    return result;
  }

  __fs34CollectionListenerCallback(
    class_instance: bi2DataInteractions,
    firestore_document: fs34RecyclerStaffOnlyMessages,
    recycler_business_uid: string,
    recycler_location_uid: string,
    consumer_uid: string,
    conversation_uid: string,
    load_sub_collection = true
  ): void {
    const self = class_instance;
    const doc = firestore_document;

    let conversation_uuid = consumer_uid + "-" + conversation_uid;
    if (conversation_uuid in self.IV_employee_conversations.value === false) {
      Vue.set(self.IV_employee_conversations.value,
        conversation_uuid,
        []);
    }
    let message_content:string | undefined = "";
    let file_name: string | undefined = undefined;
    let file_size: number | undefined = undefined;

    let new_entry:any = [
      firestore_document.recycler_only_message_uid,
      firestore_document.message_type,
      firestore_document.employee_uid,
      firestore_document.timestamp
    ];
    

    
    if (firestore_document.message_type === 1 && firestore_document.message_content !== undefined) {
      if(firestore_document.message_content.indexOf("https://") !== -1) {

        var urlRegex = new RegExp('(https:\/\/\S.*|https:\/\/.*$)');
        //@ts-ignore
          let match_result = firestore_document.message_content.match(urlRegex);
          if (match_result !== null ) {
            let base_url = "";
            let text_after_url = "";
            if( match_result[0].indexOf(" ") !== -1) {
              let split_results = match_result[0].split(" ");
              //@ts-ignore
              base_url = split_results.splice(0,1)
              text_after_url = split_results.join(" ");
            } else {
              base_url = match_result[0];
            }
            //console.log(url);
            let formatted_url = "<a class='text-blue-700' href='" + base_url + "'>" + base_url + "</a>";
            

            if(text_after_url !="") {
              formatted_url = formatted_url + " " + text_after_url;
            }
            firestore_document.message_content = firestore_document.message_content.replace(urlRegex,formatted_url);            
          }
      }
      new_entry.push(firestore_document.message_content);
    }
    else if (firestore_document.message_type === 2 
      ||firestore_document.message_type === 3){
        new_entry.push(firestore_document.uploaded_file_link);
        new_entry.push(firestore_document.file_name);
        new_entry.push(firestore_document.uploaded_file_size);
        if(firestore_document.file_name === undefined ){
          firestore_document.file_name = firestore_document.uploaded_file_link;
        }
        //@ts-ignore this won't be undefiend because it only runs on messages with files
        if(firestore_document.file_name.match(/\.(jpg|jpeg|png|gif)$/i)) {
          new_entry.push(1); // set image file type flag
        //@ts-ignore this won't be undefiend because it only runs on messages with files
        } else  if(firestore_document.file_name.match(/\.(mpg|mpeg|mp4|mov)$/i)) {
          new_entry.push(2); // set video file type flag
        } else {
          new_entry.push(3);
        }    
    }

    self.IV_employee_conversations.value[conversation_uuid].push(new_entry);
  
  }




  loadCacheUpdatedRecordsData(
    class_instance: bi2DataInteractions,
    recycler_business_uid: string,
    recycler_location_uid: string,
    document_key_prefix: string
  ): Result {
    const self = class_instance;
    let result: Result = {
      debug_data: [],
      return_msg: "bi2DataInteractions:loadCacheUpdatedRecordsData: ",
      success: true,
    };

    let doc_generated_time = undefined;

    const query = collection(
      self.IV_firebase_app,
      `fs50CacheUpdatedRecords/${document_key_prefix + recycler_location_uid}`
    );
    const ref_cache_updated_record = doc(
      self.IV_firebase_app,
      "fs50CacheUpdatedRecords",
      document_key_prefix + recycler_location_uid
    );

    getDoc(ref_cache_updated_record).then((doc_snapshot) => {
      const doc_cache_rec = doc_snapshot.data() as fs50CacheUpdatedRecords;
      doc_generated_time = doc_cache_rec.generation_time;

      if (document_key_prefix == "fs19_") {
        class_instance.loadBrowserCacheConsumerContactSearchData(
          class_instance,
          recycler_business_uid,
          recycler_location_uid,
          doc_generated_time
        );
      } else if (document_key_prefix == "fs20_") {
        class_instance.loadBrowserCacheConversationSearchData(
          class_instance,
          recycler_business_uid,
          recycler_location_uid,
          doc_generated_time
        );
      }
      /*else if (document_key_prefix == "fs25_") {
        class_instance.loadCachedRecyclerMetaData(
          class_instance,
          recycler_location_uid,
          doc_generated_time
        );
      }*/
    });

    return result;
  }

  loadBrowserCacheConsumerContactSearchData(
    class_instance: bi2DataInteractions,
    recycler_business_uid: string,
    recycler_location_uid: string,
    generated_time: Date | undefined
  ): Result {
    const self = class_instance;
    let result: Result = {
      debug_data: [],
      return_msg:
        "bi2DataInteractions:loadBrowserCacheConsumerContactSearchData: ",
      success: true,
    };

    const local_fs19_last_downloaded = localStorage.getItem(
      recycler_location_uid + "_fs19_last_downloaded"
    );

    if (
      generated_time == undefined ||
      local_fs19_last_downloaded == null ||
      new Date(local_fs19_last_downloaded) < generated_time
    ) {
      const ref_cache_contact_search = doc(
        self.IV_firebase_app,
        "fs19BrowserCacheConsumerContactSearch",
        recycler_location_uid
      );

      getDoc(ref_cache_contact_search).then((doc_snapshot) => {
        const doc_cache_contact_search =
          doc_snapshot.data() as fs19BrowserCacheConsumerContactSearch;

        // setting json_cache_data to localStorag
        localStorage.setItem(
          recycler_location_uid + "_fs19",
          doc_cache_contact_search.json_cache_data
        );

        // setting last downloaded time
        localStorage.setItem(
          recycler_location_uid + "_fs19_last_downloaded",
          generated_time != undefined
            ? doc_cache_contact_search.generation_time.toString()
            : ""
        );

        // no need to load from localStorage as already in doc_cache_contact_search.json_cache_data so using from here
        const cached_data = JSON.parse(
          doc_cache_contact_search.json_cache_data
        );
        self.IV_consumer_contact_search_data = ref(cached_data);

        self.startBrowserCacheConsumerContactsSearchUpdatesListener(
          self,
          recycler_business_uid,
          recycler_location_uid
        );
      });
    } else {
      const local_fs19_data = localStorage.getItem(
        recycler_location_uid + "_fs19"
      );

      if (local_fs19_data != null) {
        const cached_data = JSON.parse(local_fs19_data);
        self.IV_consumer_contact_search_data = ref(cached_data);
        self.startBrowserCacheConsumerContactsSearchUpdatesListener(
          self,
          recycler_business_uid,
          recycler_location_uid
        );
      }
    }

    return result;
  }

  startBrowserCacheConsumerContactsSearchUpdatesListener(
    class_instance: bi2DataInteractions,
    recycler_business_uid: string,
    recycler_location_uid: string
  ): Result {
    const self = class_instance;
    
    let result: Result = {
      debug_data: [],
      return_msg:
        "bi2DataInteractions:startBrowserCacheConsumerContactsSearchUpdatesListener: ",
      success: true,
    };

    const ids = self.IV_schema_ids
    let call_result = self.listenerExistsCheck(self,
    [ids.fs1,ids.fs2,ids.fs23],
    [recycler_business_uid,recycler_location_uid,"collection"]
    )
    result.debug_data.push(call_result);
    if (call_result.exists === true) {
      result.return_msg += `Listener already started for fs23.../${recycler_location_uid}`;
      result.success = false;
      return result;
    }

    const collectionRef = collection(
      self.IV_firebase_app,
      "fs23BrowserCacheConsumerContactsSearchUpdates"
    );
    const queryRef = query(
      collectionRef,
      where("recycler_location_uid", "==", recycler_location_uid),
      orderBy("update_time")
    );

    const unsub = onSnapshot(queryRef, (doc_snapshots) => {
      doc_snapshots.forEach((doc_snapshot) => {
        const doc_data =
          doc_snapshot.data() as fs23BrowserCacheConsumerContactsSearchUpdates;
        self.__fs23BrowserCacheConsumerContactsSearchUpdatesCallback(
          self,
          doc_data,
          recycler_location_uid
        );
      });
    });

     call_result = self.registerListener(self,
      [ids.fs1,ids.fs2,ids.fs23],
      [recycler_business_uid,recycler_location_uid,"collection"],
      unsub
      )
      result.debug_data.push(call_result);

    return result;
  }

  __fs23BrowserCacheConsumerContactsSearchUpdatesCallback(
    class_instance: bi2DataInteractions,
    firestore_document: fs23BrowserCacheConsumerContactsSearchUpdates,
    recycler_location_uid: string,
    load_sub_collection = true
  ): void {
    const self = class_instance;
    //from: fs23
    const last_applied_time =
      self.IV_fs23_last_update_applied_time_by_location[recycler_location_uid];

    if (
      last_applied_time != undefined &&
      last_applied_time >= firestore_document.update_time
    ) {
      return;
    }

    // TODO: here apply updates to the IV_consumer_contact_search_data

    //once update is applied set last updated time for this location
    self.IV_fs23_last_update_applied_time_by_location[recycler_location_uid] =
      firestore_document.update_time;

  }

  loadBrowserCacheConversationSearchData(
    class_instance: bi2DataInteractions,
    recycler_business_uid: string,
    recycler_location_uid: string,
    generated_time: Date | undefined
  ): Result {
    const self = class_instance;
    let result: Result = {
      debug_data: [],
      return_msg:
        "bi2DataInteractions:loadBrowserCacheConversationSearchData: ",
      success: true,
    };

    // TODO - complete this

    const local_fs20_last_downloaded = localStorage.getItem(
      recycler_location_uid + "_fs20_last_downloaded"
    );

    if (
      generated_time == undefined ||
      local_fs20_last_downloaded == null ||
      new Date(local_fs20_last_downloaded) < generated_time
    ) {
      const ref_cache_conversation_search = doc(
        self.IV_firebase_app,
        "fs20BrowserCacheConversationSearch",
        recycler_location_uid
      );

      getDoc(ref_cache_conversation_search).then((doc_snapshot) => {
        const doc_cache_conversation_search =
          doc_snapshot.data() as fs20BrowserCacheConversationSearch;
        // setting json_cache_data to localStorage
        localStorage.setItem(
          recycler_location_uid + "_fs20",
          doc_cache_conversation_search.json_cache_data
        );

        // setting last downloaded time
        localStorage.setItem(
          recycler_location_uid + "_fs20_last_downloaded",
          generated_time != undefined
            ? doc_cache_conversation_search.generation_time.toString()
            : ""
        );

        // no need to load from localStorage as already in doc_cache_converstaion_search.json_cache_data so using from here
        const cached_data = JSON.parse(
          doc_cache_conversation_search.json_cache_data
        );
        self.IV_conversation_search_data = ref(cached_data);
        self.startBrowserCacheConversationSearchUpdatesListener(
          self,
          recycler_business_uid,
          recycler_location_uid
        );
      });
    } else {
      const local_fs20_data = localStorage.getItem(
        recycler_location_uid + "_fs20"
      );
      if (local_fs20_data != null) {
        // var cached_data : typeof_search_data =  JSON.parse(local_fs20_data);
        // self.IV_consumer_converstaion_search_data = ref(cached_data)
        const cached_data = JSON.parse(local_fs20_data);
        self.IV_conversation_search_data = ref(cached_data);
        self.startBrowserCacheConversationSearchUpdatesListener(
          self,
          recycler_business_uid,
          recycler_location_uid
        );
      }
    }

    return result;
  }

  startBrowserCacheConversationSearchUpdatesListener(
    class_instance: bi2DataInteractions,
    recycler_business_uid: string,
    recycler_location_uid: string
  ): Result {
    const self = class_instance;
    
    let result: Result = {
      debug_data: [],
      return_msg:
        "bi2DataInteractions:startBrowserCacheConversationSearchUpdatesListener: ",
      success: true,
    };

    const ids = self.IV_schema_ids
    let call_result = self.listenerExistsCheck(self,
    [ids.fs1,ids.fs2,ids.fs21],
    [recycler_business_uid,recycler_location_uid,"collection"]
    )
    result.debug_data.push(call_result);
    if (call_result.exists === true) {
      result.return_msg += `Listener already started for fs21.../${recycler_location_uid}`;
      result.success = false;
      return result;
    }

    const collectionRef = collection(
      self.IV_firebase_app,
      "fs21BrowserCacheConversationSearchUpdates"
    );
    const queryRef = query(
      collectionRef,
      where("recycler_location_uid", "==", recycler_location_uid),
      orderBy("update_time")
    );

    const unsub = onSnapshot(queryRef, (doc_snapshots) => {
      doc_snapshots.forEach((doc_snapshot) => {
        const doc_data =
          doc_snapshot.data() as fs21BrowserCacheConversationSearchUpdates;
        self.__fs21BrowserCacheConversationSearchUpdatesCallback(
          self,
          doc_data,
          recycler_location_uid
        );
      });
    });

    call_result = self.registerListener(self,
      [ids.fs1,ids.fs2,ids.fs21],
      [recycler_business_uid,recycler_location_uid,"collection"],
      unsub
      )
      result.debug_data.push(call_result);

    return result;
  }

  __fs21BrowserCacheConversationSearchUpdatesCallback(
    class_instance: bi2DataInteractions,
    firestore_document: fs21BrowserCacheConversationSearchUpdates,
    recycler_location_uid: string,
    load_sub_collection = true
  ): void {
    const self = class_instance;
    //from: fs21
    const last_applied_time =
      self.IV_fs21_last_update_applied_time_by_location[recycler_location_uid];

    if (
      last_applied_time != undefined &&
      last_applied_time >= firestore_document.update_time
    ) {
      return;
    }

    // TODO: here apply updates to the IV_consumer_contact_search_data

    //once update is applied set last updated time for this location
    self.IV_fs21_last_update_applied_time_by_location[recycler_location_uid] =
      firestore_document.update_time;

  }

  startRecyclerLocationInvitesListener(
    class_instance: bi2DataInteractions,
    load_sub_collection = true

  ): Result {
    const self = class_instance;
    

    let result: Result = {
      debug_data: [],
      return_msg: "bi2DataInteractions:startRecyclerLocationInvitesListener: ",
      success: true,
    };

    let call_result = self.listenerExistsCheck(self,[self.IV_schema_ids.fs5],["collection"]);
    result.debug_data.push(call_result);
    if (call_result.exists === true) {
      result.return_msg += `Listener already started for fs5RecyclerLocationInvites`;
      result.success = true;
      return result;
    }

    // Generate reference
    const collectionRef = collection(
      self.IV_firebase_app,
      "fs5RecyclerLocationInvites"
    )

    const queryRef = query(
      collectionRef,
      where("email_address", "==", self.IV_loggedin_user_info.email)
    );

    const unsub = onSnapshot(queryRef, (doc_snapshots) => {
      doc_snapshots.forEach((doc_snapshot) => {
        let employee_invite = doc_snapshot.data() as fs5RecyclerLocationInvites;
        if (!employee_invite.email_address) { return; }

        const fs76Query = collection(self.IV_firebase_app,`fs5RecyclerLocationInvites/${employee_invite.email_address}/fs76RecyclerLocationInvitesSubcollection`);
        getDocs(fs76Query).then((employee_invite_docs) => {
          employee_invite_docs.forEach((employee_invite_doc) => {
            self.__fs5Fs76RecyclerLocationInvitesWithSubCollectionsCallback(
              self,
              employee_invite_doc.data() as fs76RecyclerLocationInvitesSubcollection,
              employee_invite_doc.id,
              load_sub_collection
            );
          })
        });
      });
    });

    self.registerListener(self,[self.IV_schema_ids.fs5],["collection"],unsub);

    return result;
  }

  __fs5Fs76RecyclerLocationInvitesWithSubCollectionsCallback(
    class_instance: bi2DataInteractions,
    invite: fs76RecyclerLocationInvitesSubcollection,
    fs76_doc_id: string, 
    load_sub_collection = true
  ): void {
    const self = class_instance;

    let existing_invites =
      self.IV_loggedin_user_active_invites.value[invite.recycler_location_uid];
      
    if (!existing_invites) {
      existing_invites = [];
    }

    existing_invites.push({ 
      permissions: invite.permissions, 
      invite_time : invite.invite_time,
      recycler_business_uid: invite.recycler_business_uid,
      recycler_location_uid: invite.recycler_location_uid,
      recycler_business_name: invite.recycler_business_name,
      recycler_location_name: invite.recycler_location_name,
      subcollection_doc_id: fs76_doc_id
    });
    
    Vue.set(
      self.IV_loggedin_user_active_invites.value,
      invite.recycler_location_uid,
      existing_invites
    );
  }

  startRecyclerLocationsPaymentApiListener(
    class_instance: bi2DataInteractions,
    fs83_id: string,
  ): Result {
    const self = class_instance;

    let result: Result = {
      debug_data: [],
      return_msg:
        "bi2DataInteractions:startRecyclerLocationsPaymentApiListener: ",
      success: true,
    };

    const doc_ref = doc(
      self.IV_firebase_app,
      `fs83PaymentApiKeys/${fs83_id}`
    );

    // TODO - check if listener already started
    const unsub = onSnapshot(doc_ref, (doc_snapshot) => {
      const doc_data = doc_snapshot.data() as fs83PaymentApiKeys;
      self.__fs83PaymentApiKeysCallback(
        self,
        doc_data,
        fs83_id
      );
    });

    return result;
  }

  __fs83PaymentApiKeysCallback(
    class_instance: bi2DataInteractions,
    firestore_document: fs83PaymentApiKeys,
    fs83_id: string,
    load_sub_collection = true
  ): void {
    const self = class_instance;
    const doc = firestore_document;

    if (!doc) { return; }

    Vue.set(self.IV_recyclers_payment_api_keys["value"], fs83_id, doc)
    
    return;
  }

  startConsumerBusinessesListener(
    class_instance: bi2DataInteractions,
    fs1_id: string,
    fs2_id: string
  ): Result {
    const self = class_instance;


    let result: Result = {
      debug_data: [],
      return_msg:
        "bi2DataInteractions:startConsumerBusinessesListener: ",
      success: true,
    };



    let ids = self.IV_schema_ids;
    let call_result = self.listenerExistsCheck(self,
      [ids.fs1,ids.fs2,ids.fs37],
      [fs1_id,fs2_id,"collection"]
      )
    result.debug_data.push(call_result)
    if ( call_result.exists === true) {
      result.return_msg += `Listener already started for fs37 collection .../${fs1_id}/${fs2_id}/`;
      result.success = true;
      return result;
    }

    const collection_ref = collection(
      self.IV_firebase_app,
      "fs1RecyclerBusiness",
      fs1_id,
      "fs2RecyclerLocation",
      fs2_id,
      "fs37ConsumerBusinesses"
    );


    const unsub = onSnapshot(collection_ref, (doc_snapshots) => {
      doc_snapshots.forEach((doc_snapshot) => {
      const doc_data = doc_snapshot.data() as fs37ConsumerBusinesses;
        self.__fs37ConsumerBusinessesListenerCallback(
          self,
          fs1_id,
          fs2_id,
          doc_data
        );
      });
    });

    call_result = self.registerListener(self,
      [ids.fs1,ids.fs2,ids.fs37],
      [fs1_id,fs2_id,"collection"],
      unsub
    )

    return result;
  }

  __fs37ConsumerBusinessesListenerCallback(
    class_instance: bi2DataInteractions,
    fs1_id: string,
    fs2_id: string,
    firestore_document: fs37ConsumerBusinesses,
    load_sub_collection = true
  ): void {
    const self = class_instance;
    const doc = firestore_document as fs37ConsumerBusinesses;

    if (!doc) { return; }

    let existing_consumers:any = {};
    //// create a lookup of existing consumer records to sync any changes from the database
    if(doc.consumer_business_uid && doc.consumer_business_uid in self.IV_consumer_businesses.value) {
      for(let entry in self.IV_consumer_businesses.value[doc.consumer_business_uid].consumer_contacts_uids_list) {
        let consumer_uid = self.IV_consumer_businesses.value[doc.consumer_business_uid].consumer_contacts_uids_list[entry];
        existing_consumers[consumer_uid] = true;
      }
    }
    ////</end> create a lookup of existing consumer records to sync any changes from the database

    Vue.set(self.IV_consumer_businesses.value, doc.consumer_business_uid as string, {
      consumer_business_uid: doc.consumer_business_uid,
      consumer_business_type_uids_list: doc.consumer_business_type_uids_list || [],
      primary_consumer_contact_uid: doc.primary_consumer_contact_uid || "",
      name: doc.name,
      city: doc.city || "",
      state: doc.state || "",
      postal_code: doc.postal_code || "",
      address_line_1: doc.address_line_1 || "",
      address_line_2: doc.address_line_2 || "",
      website: doc.website || "",
      facebook_url: doc.facebook_url || "",
      consumer_contacts_uids_list: doc.consumer_contacts_uids_list || [],
      is_deactivated: doc.is_deactivated || false
    });

    //// update any existing consumer association records that have changed
    let loop1 = 0;
    let new_consumers = [];
    //remove any consumers that already have records set and add new consumers to a list
    if(doc.consumer_contacts_uids_list) {
      while(loop1 < doc.consumer_contacts_uids_list.length) {
        if(doc.consumer_contacts_uids_list[loop1] in existing_consumers) {
          delete existing_consumers[doc.consumer_contacts_uids_list[loop1]];
        } else {
          new_consumers.push(doc.consumer_contacts_uids_list[loop1]);
        }
        loop1 +=1;
      }
    }

    //remove consumers no longer associated with the business
    for(let consumer_uid in existing_consumers) {
      let existing_business_uids = self.IV_consumer_business_joins.value[consumer_uid];
      loop1 =0;
      let max_index = 0;
      max_index = self.IV_consumer_business_joins.value[consumer_uid].length;
      while(loop1 < max_index) {
        if(existing_business_uids[loop1] === doc.consumer_business_uid as string) {
          self.IV_consumer_business_joins.value[consumer_uid].splice(loop1,1);
          max_index = self.IV_consumer_business_joins.value[consumer_uid].length;
          loop1 -= 1;
        }
        loop1 += 1;
      }
    }

    //add new consumers associated with the business
    loop1 =0;
    while(loop1 < new_consumers.length) {
      if (doc.consumer_business_uid) {
        if( new_consumers[loop1] in self.IV_consumer_business_joins.value === false) {
          Vue.set(self.IV_consumer_business_joins.value, new_consumers[loop1], [doc.consumer_business_uid]);
        } else {
          self.IV_consumer_business_joins.value[new_consumers[loop1]].push(doc.consumer_business_uid);
        }
      }
      loop1 += 1;
    }
    ////</end> update any existing consumer association records that have changed
    self.IV_consumer_business_data_last_changed.value = Date();
    return;
  }

  startConsumersActivityStatusListener(
    class_instance: bi2DataInteractions,
    recycler_business_uid: string,
    recycler_location_uid: string,
    employe_uid: string,
  ): Result {
    const self = class_instance;

    let result: Result = {
      debug_data: [],
      return_msg:
        "bi2DataInteractions:startRecyclerLocationsPaymentApiListener: ",
      success: true,
    };

    // TODO - check if listener already there

    const collection_ref = collection(
      self.IV_firebase_app,
      "fs1RecyclerBusiness",
      recycler_business_uid,
      "fs2RecyclerLocation",
      recycler_location_uid,
      "fs101EmployeeConversationStatuses",
      employe_uid,
      "fs102ConsumerConversationStatuses"
    );

    const unsub = onSnapshot(collection_ref, (doc_snapshots) => {
      doc_snapshots.forEach(async (doc_snapshot) => {

        // Get all documents from the nested collection
        const nestedCollectionRef = collection(collection_ref, doc_snapshot.id, "fs103ConversationStatuses");
        const unsub_nested = onSnapshot(nestedCollectionRef, (nested_doc_snapshots) => {
          nested_doc_snapshots.forEach((nested_doc_snapshot) => {
            const doc_data = nested_doc_snapshot.data() as fs103ConversationStatuses;
            self.__fs103ConversationStatusesCallback(
              self,
              doc_data,
              doc_snapshot.id,
              nested_doc_snapshot.id
            );
          });
        })
      });
    });

    return result;
  }

  __fs103ConversationStatusesCallback(
    class_instance: bi2DataInteractions,
    firestore_document: fs103ConversationStatuses,
    consumer_uid: string,
    conversation_uid: string
  ): void {
    const self = class_instance;
    const doc = firestore_document;

    if (!doc) { return; }

    let consumer_and_conversation_uuid = `${consumer_uid}-${conversation_uid}`;

    Vue.set(self.IV_conversations_activity_statuses.value, consumer_and_conversation_uuid, {
      last_activity_time: doc.last_activity_time,
      consumer_typing_status: doc.consumer_typing_status,
      employee_typing_status: doc.employee_typing_status
    });

    return;
  }

  startLocationCandidatesListenerForRecyclerLocation(
    class_instance: bi2DataInteractions,
    recycler_location_uid: string
  ): Result {
    const self = class_instance;

    let result: Result = {
      debug_data: [],
      return_msg:
        "bi2DataInteractions:startLocationCandidatesListenerForRecyclerLocation: ",
      success: true,
    };

    const doc_ref = doc(
      self.IV_firebase_app,
      `fs105LocationCandidates/${recycler_location_uid}`
    );

    // TODO - check if listener already started
    const unsub = onSnapshot(doc_ref, (doc_snapshot) => {
      const doc_data = doc_snapshot.data() as fs105LocationCandidates;
      self.__fs105LocationCandidatesListenerCallback(
        self,
        doc_data,
        recycler_location_uid
      );
    });

    return result;
  }

  __fs105LocationCandidatesListenerCallback(
    class_instance: bi2DataInteractions,
    firestore_document: fs105LocationCandidates,
    recycler_location_uid: string
  ): void {
    const self = class_instance;

    if (!firestore_document) { return; };
    Vue.set(self.IV_recyclers_location_candidates["value"], recycler_location_uid, firestore_document)

    return;
  }

  startConversationNotificationsListener(
    class_instance: bi2DataInteractions,
    fs1_id: string,
    fs2_id: string
  ): Result {

    const self = class_instance;

    let result: Result = {
      debug_data: [],
      return_msg:
        "bi2DataInteractions:startConversationNotificationsListener: ",
      success: true,
    };

    // TODO fix and enable check

    // let ids = self.IV_schema_ids;
    // let call_result = self.listenerExistsCheck(
    //   self,
    //   [ids.fs1, ids.fs2, ids.fs112],
    //   [fs1_id, fs2_id, "collection"]
    // )

    // result.debug_data.push(call_result)
    // if (call_result.exists === true) {
    //   result.return_msg += `Listener already started for fs112 collection .../${fs1_id}/${fs2_id}/`;
    //   result.success = true;
    //   return result;
    // }

    const collection_ref = collection(
      self.IV_firebase_app,
      "fs1RecyclerBusiness",
      fs1_id,
      "fs2RecyclerLocation",
      fs2_id,
      "fs112ConsumerConversationNotifications"
    );

    const unsub = onSnapshot(collection_ref, (doc_snapshots) => {
      doc_snapshots.docChanges().forEach((change) => {
        
        if (change.type === "added" || change.type === "modified") {

          const doc_data = change.doc.data() as fs112ConsumerConversationNotifications;
          self.__fs112ConsumerConversationNotificationsListenerCallback(
            self,
            fs1_id,
            fs2_id,
            doc_data,
            change.doc.id
          );
        }

        // Remove from IV
        if (change.type === "removed") {
          Vue.delete(self.IV_user_notifications.value, change.doc.id);
        }
      })
    });

    // TODO fix and enable check

    // call_result = self.registerListener(self,
    //   [ids.fs1, ids.fs2, ids.fs112],
    //   [fs1_id, fs2_id, "collection"],
    //   unsub
    // )

    return result;
  }

  __fs112ConsumerConversationNotificationsListenerCallback(
    class_instance: bi2DataInteractions,
    fs1_id: string,
    fs2_id: string,
    firestore_document: fs112ConsumerConversationNotifications,
    doc_uid: string
  ): void {
    const self = class_instance;
    const doc = firestore_document as fs112ConsumerConversationNotifications;

    if (!doc) { return; }

    Vue.set(self.IV_user_notifications.value, doc_uid, {
      type: doc.type,
      timestamp: doc.timestamp,
      summary: doc.summary || "",
      description: doc.description || "",
      json_data: doc.json_data || "",
      consumer_conversation_uid: doc.consumer_conversation_uid,
      consumer_uid: doc.consumer_uid,
      conversation_uid: doc.conversation_uid,
      payment_link_uid: doc.payment_link_uid || "",
      force_mark_unread: doc.force_mark_unread,
    });

    return;
  }

  startReadNotificationsListener(
    class_instance: bi2DataInteractions,
    fs1_id: string,
    fs2_id: string,
    fs3_id: string,
  ): Result {

    const self = class_instance;

    let result: Result = {
      debug_data: [],
      return_msg:
        "bi2DataInteractions:startReadNotificationsListener: ",
      success: true,
    };

    // TODO fix and enable check

    // let ids = self.IV_schema_ids;
    // let call_result = self.listenerExistsCheck(
    //   self,
    //   [ids.fs1, ids.fs2, ids.fs125],
    //   [fs1_id, fs2_id, "collection"]
    // )

    // result.debug_data.push(call_result)
    // if (call_result.exists === true) {
    //   result.return_msg += `Listener already started for fs125 collection .../${fs1_id}/${fs2_id}/`;
    //   result.success = true;
    //   return result;
    // }

    const collection_ref = collection(
      self.IV_firebase_app,
      "fs1RecyclerBusiness",
      fs1_id,
      "fs2RecyclerLocation",
      fs2_id,
      "fs3Employees",
      fs3_id,
      "fs125ReadNotifications"
    );

    const unsub = onSnapshot(collection_ref, (doc_snapshots) => {
      doc_snapshots.forEach((doc_snapshot) => {
        const doc_data = doc_snapshot.data() as fs125ReadNotifications;
        self.__fs125ReadNotificationsListenerCallback(
          self,
          fs1_id,
          fs2_id,
          fs3_id,
          doc_data,
          doc_snapshot.id
        );
      });
    });

    // TODO fix and enable check

    // call_result = self.registerListener(self,
    //   [ids.fs1, ids.fs2, ids.fs125],
    //   [fs1_id, fs2_id, "collection"],
    //   unsub
    // )

    return result;
  }

  __fs125ReadNotificationsListenerCallback(
    class_instance: bi2DataInteractions,
    fs1_id: string,
    fs2_id: string,
    fs3_id: string,
    firestore_document: fs125ReadNotifications,
    doc_uid: string
  ): void {
    const self = class_instance;
    const doc = firestore_document as fs125ReadNotifications;

    if (!doc) { return; }

    Vue.set(self.IV_user_notifications_read_time.value, doc_uid, {
      consumer_contact_uid: doc.consumer_contact_uid,
      conversation_uid: doc.conversation_uid,
      read_message_time: doc.read_message_time,
      read_notification_time: doc.read_notification_time
    });

    return;
  }

  // Runs every 1 seconds
  updateConversationsStatusAndNewMessageCount() {
    const self = this;

    ///// Set consumer's activity status if fetched already
    for (let consumer_and_conversation_uuid in self.IV_conversations_activity_statuses.value) {

      let status = ""
      const activity_status = self.IV_conversations_activity_statuses.value[consumer_and_conversation_uuid];
      if(activity_status === undefined) {continue;}
      if(consumer_and_conversation_uuid in self.IV_consumer_conversations.value === false) {continue;}

      const currDate = Math.floor(new Date().getTime()/1000);
      
      //@ts-ignore:
      if (!activity_status.last_activity_time) {
        status = "offline";
      }

      //@ts-ignore:
      else if ((currDate - activity_status.last_activity_time.seconds) >= self.IV_activity_status_offline_time) {
        status = "offline";
      }

      //@ts-ignore:
      else if ((currDate - activity_status.last_activity_time.seconds) >= self.IV_activity_status_inactive_time) {
        status = "inactive";
      }

      //@ts-ignore:
      else if ((currDate - activity_status.last_activity_time.seconds) < self.IV_activity_status_inactive_time) {
        status = "online";
      }


      Vue.set(self.IV_consumer_conversations.value[consumer_and_conversation_uuid], 'activity_status', status);
      Vue.set(self.IV_consumer_conversations.value[consumer_and_conversation_uuid], 'last_activity_time', activity_status.last_activity_time);
      Vue.set(self.IV_consumer_conversations.value[consumer_and_conversation_uuid], 'consumer_typing_status', activity_status.consumer_typing_status);
      Vue.set(self.IV_consumer_conversations.value[consumer_and_conversation_uuid], 'employee_typing_status', activity_status.employee_typing_status);
    }
    ///// </end> Set consumer's activity status if fetched already

    ///// Set unread messages count for conversations
    for (let consumer_conversation_uid in self.IV_consumer_conversation_messages.value) {
      const consumer_conversation = self.IV_consumer_conversation_messages.value[consumer_conversation_uid];
      let count = 0;

      const conversation_messages = self.IV_consumer_conversation_messages.value[consumer_conversation_uid];
      const message_indexes = self.IV_al_consumer_conversation_messages;
      
      const last_read_info = self.IV_user_notifications_read_time.value[consumer_conversation_uid];

      for (let message of conversation_messages) { 
        if (!last_read_info) { continue; }

        const last_read_time = last_read_info.read_message_time || new Date(-1);
        const message_time = message[message_indexes.timestamp] as Date;
        const senderType = message[message_indexes.message_sender];

        if (message_time > last_read_time) {
          if (senderType === 1) {
            count += 1; 
          }
        }
      }

      Vue.set(self.IV_conversations_unread_messages_count.value, consumer_conversation_uid, count);
    }
    ///// </end> Set unread messages count for conversations
  }

  /* We are no longer using fs25
  loadCachedRecyclerMetaData(
    class_instance: bi2DataInteractions,
    recycler_location_uid: string,
    generated_time: Date | undefined
  ): Result {
    const self = class_instance;
    let result: Result = {
      debug_data: [],
      return_msg: "bi2DataInteractions:loadCachedRecyclerMetaData: ",
      success: true,
    };

    // TODO - complete this

    return result;
  }
  */
}
