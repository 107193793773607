<template>
  <!-- Customers filters  -->
  <div id="p1s4c3" class="p-3 overflow-y-scroll hide-scrollbar">
    <h1 class="text-lg font-bold text-center">Filters</h1>

    <div class="grid grid-cols-6 gap-6 items-center">

      <!-- Search area -->
      <label class="col-span-1">Keyword:
        <span class="tooltip">
          ?
          <span class="tooltiptext whitespace-pre-line">
            Search by Order Number, Description, Card Holder Name, etc.
          </span>
        </span>
      </label>
      <SearchBar class="col-span-2" containerClasses="w-full" inputClasses="w-full" v-model="DV_searchText"/>
      
      <!-- Entries Per Page filter -->
      <div class="justify-center col-span-2 text-center grid grid-cols-3" style="display:flex; align-items:center;">
        <label class="font-medium text-sm" for="entrierPerPage" style="margin-right:10px;">Show</label>
        <select 
          style="width:auto; margin-right:10px;" 
          id="entriesPerPage" 
          name="entriesPerPage" 
          v-model="DV_selectedNumberOfEntriesPerPage"
          @change="selectEntriesPerPageFilter($event.target.value)"
        >
          <option v-for="entries in DV_numberOfEntriesPerPage">
            {{entries}}
          </option>
        </select>
        <label class="font-medium text-sm">Entries Per Page</label>
      </div>
      
      <!-- Payment Status filter -->
      <div class="text-center items-center mb-5">
        <label class="font-medium text-sm" for="paymentStatus">Status</label>
        <select 
          id="paymentStatus" 
          name="paymentStatus" 
          class="dropdown-select" 
          v-model="DV_selectedPaymentStatusFilter"
          @change="selectPaymentStatusFilter($event.target.value)"
        >
          <option v-for="status in paymentStatusList">
            {{status}}
          </option>
        </select>
      </div>

      <!-- Date range filter -->
      <label class="font-medium text-sm" for="startDate">Start Date:</label>
        <input 
          type="date"
          style="width:auto;" 
          :max="DV_dateToday" 
          id="startDate" 
          v-model="DV_startDate" 
          @change="selectStartDateFilter($event.target.value)"
        >

        <label class="font-medium text-sm" for="endDate">End Date:</label>
        <input 
          type="date" 
          style="width:auto;"
          :min="DV_startDate" 
          :max="DV_dateToday" 
          id="endDate" 
          v-model="DV_endDate" 
          @change="selectEndDateFilter($event.target.value)"
        >

      <!-- Quick Date filter -->
      <label class="font-medium text-sm" for="quickDate">Quick Date Selections:</label>
      <select 
        id="quickDate" 
        name="quickDate" 
        class="dropdown-select" 
        v-model="DV_selectedQuickDateFilter"
        @change="selectQuickDateFilter($event.target.value)"
      >
        <option v-for="dateFilter in DV_quickDateSelections">
          {{dateFilter}}
        </option>
      </select>

    </div>
  </div>
</template>

<script>
import _ from "lodash"
import SearchBar from "../shared/SearchBar.vue";
import moment from 'moment';

// Set the start of the week to Monday
moment.updateLocale('en', {
  week: { dow: 1 } // Set the start of the week to Monday
});

export default {
  name: "p1s4c3PaymentLinkFilters",

  props: ["paymentStatusList"],

  components: {SearchBar},

  data() {
    return {
      DV_searchText: "",
      DV_numberOfEntriesPerPage: [10, 25, 50, 100],
      DV_startDate: moment().format("YYYY-MM-DD"),
      DV_endDate: moment().format("YYYY-MM-DD"),
      DV_dateToday: moment().format("YYYY-MM-DD"),
      DV_selectedPaymentStatusFilter: "All",
      DV_quickDateSelections: ["Today", "Yesterday", "This Week", "Last Week", "This Month", "Last Month", "This Year", "Last Year"],
      DV_selectedQuickDateFilter: "Today",
      DV_selectedNumberOfEntriesPerPage: 10,
      moment: moment,
    };
  },

  mounted() {
    // load cached data
    /*
    const cachedPaymentStatusFilter = window.localStorage.getItem("p1s4c3_payment_status_filter") || "";
    const cachedEntriesPerPageFilter = window.localStorage.getItem("p1s4c3_entries_perpage_filter") || "";
    const cachedStartDateFilter = window.localStorage.getItem("p1s4c3_start_date_filter") || "";
    const cachedEndDateFilter = window.localStorage.getItem("p1s4c3_end_date_filter") || "";
    const cachedQuickDateFilter = window.localStorage.getItem("p1s4c3_quick_date_filter") || "";

    if (cachedPaymentStatusFilter) {
      this.DV_selectedPaymentStatusFilter = cachedPaymentStatusFilter;
      this.$emit("event-payment-status-filter-selected", cachedPaymentStatusFilter);
    }

    if (cachedEntriesPerPageFilter) {
      this.DV_selectedNumberOfEntriesPerPage = cachedEntriesPerPageFilter;
      this.$emit("event-entries-per-page-filter-selected", cachedEntriesPerPageFilter)
    }

    if (cachedStartDateFilter) {
      this.DV_startDate = cachedStartDateFilter;
      this.$emit("event-start-date-filter-selected", cachedStartDateFilter);
    }

    if (cachedEndDateFilter) {
      this.DV_endDate = cachedEndDateFilter;
      this.$emit("event-end-date-filter-selected", cachedEndDateFilter);
    }

    if (cachedQuickDateFilter) {
      this.DV_selectedQuickDateFilter = cachedQuickDateFilter
      this.$emit("event-quick-date-filter-selected", cachedQuickDateFilter);
    }
 */     
  },

  methods: { 
    selectPaymentStatusFilter(filter) {
      window.localStorage.setItem("p1s4c3_payment_status_filter", filter)
      this.$emit("event-payment-status-filter-selected", filter)
    },

    selectEntriesPerPageFilter(filter) {
      window.localStorage.setItem("p1s4c3_entries_perpage_filter", filter)
      this.$emit("event-entries-per-page-filter-selected", filter)
    },

    selectStartDateFilter(filter) {
      window.localStorage.setItem("p1s4c3_start_date_filter", filter)
      this.$emit("event-start-date-filter-selected", filter)
    },
    
    selectEndDateFilter(filter) {
      window.localStorage.setItem("p1s4c3_end_date_filter", filter)
      this.$emit("event-end-date-filter-selected", filter)
    },

    selectQuickDateFilter(filter) {
      window.localStorage.setItem("p1s4c3_quick_date_filter", filter)
      this.$emit("event-quick-date-filter-selected", filter)
    }
  },

  watch: {
    DV_searchText: {
      handler() {
        this.$emit("event-search-filter-selected", this.DV_searchText)
      }
    },

   DV_selectedQuickDateFilter: {
    handler() {
      const filters = {
        "Today": {
          start: moment(),
          end: moment(),
        },
        "Yesterday": {
          start: moment().subtract(1, "days"),
          end: moment().subtract(1, "days"),
        },
        "This Week": {
          start: moment().startOf("week"),
          end: moment().endOf("week").isAfter(moment()) ? moment() : moment().endOf("week"),
        },
        "Last Week": {
          start: moment().subtract(1, "weeks").startOf("week"),
          end: moment().subtract(1, "weeks").endOf("week"),
        },
        "This Month": {
          start: moment().startOf("month"),
          end: moment().endOf("month").isAfter(moment()) ? moment() : moment().endOf("month"),
        },
        "Last Month": {
          start: moment().subtract(1, "months").startOf("month"),
          end: moment().subtract(1, "months").endOf("month"),
        },
        "This Year": {
          start: moment().startOf("year"),
          end: moment().endOf("year").isAfter(moment()) ? moment() : moment().endOf("year"),
        },
        "Last Year": {
          start: moment().subtract(1, "years").startOf("year"),
          end: moment().subtract(1, "years").endOf("year"),
        },
      };

      const selectedFilter = filters[this.DV_selectedQuickDateFilter];

      if (selectedFilter) {
        this.DV_startDate = selectedFilter.start.format("YYYY-MM-DD");
        this.DV_endDate = selectedFilter.end.format("YYYY-MM-DD");
      }

      this.selectStartDateFilter(this.DV_startDate);
      this.selectEndDateFilter(this.DV_endDate);
    }
   }
  }
};
</script>
